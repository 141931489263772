<template>
  <section class="content">
    <div class="container-fluid" data-app>
        <div class="row">
          <div class="col-12">


            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Επιλογή ημερομηνιών</h3>
              </div>
              <div class="card-body">

                <form @submit.prevent="searchInvoices()">
                    <div class="row">

                        <div class="col-md-6">
                          <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">
                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. έκδοσης από</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="dateFrom" 
                                    @selected="setDateFrom" 
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">
                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. έκδοσης έως</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="dateTo" 
                                    @selected="setDateTo" 
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>

                </form>

              </div>
            </div>
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Παραστατικών</h3>

                <div class="card-tools">

                    <button type="button" class="btn btn-sm bg-primary white" @click="downloadAll()">
                        <i class="fa fa-download"></i>
                        Λήψη όλων
                    </button>
                
                </div>

              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Είδος Παραστ.</th>
                      <th>Αρ. Παραστ.</th>
                      <th>Αποστολέας</th>
                      <th>Παραλήπτης</th>
                      <th>Χρέωση σε</th>
                      <th>Ημ. Έκδοσης</th>
                      <th>Ποσό</th>
                      <th>Υπόλοιπο</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="invoice in invoices.data" :key="invoice.id">

                      <td>{{invoice.id}}</td>
                      <td>{{invoiceTypesWithKeys[invoice.invoice_type_id]}}</td>
                      <td>{{invoice.invoice_full_number}}</td>
                      <td>{{(typeof clients[invoice.client_sender_id] != 'undefined'?clients[invoice.client_sender_id]:invoice.client_sender_id)}}</td>
                      <td>{{(typeof clients[invoice.client_receiver_id] != 'undefined'?clients[invoice.client_receiver_id]:invoice.client_receiver_id)}}</td>
                      <td>{{(typeof clients[invoice.client_charged_id] != 'undefined'?clients[invoice.client_charged_id]:invoice.client_charged_id)}}</td>
                      <td>{{$moment(invoice.issue_date).format('DD/MM/yyyy')}} {{invoice.issue_time}}</td>
                      <td>{{(invoice.sum_cost !== ''?invoice.sum_cost:0)}}€</td>
                      <td>{{(invoice.balance !== ''?invoice.balance:0)}}€</td>
                      <td>
                        <div class="row pt-3">
                          <button type="button" class="btn btn-primary" @click="downloadPDF(invoice)"><i class="fa fa-download"></i></button>
                          <!-- <router-link :to="{ path: '/invoices/edit/' + invoice.id }">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία και Εκτύπωση Παραστατικού">
                              <i class="fa fa-edit white"></i>
                            </button>
                          </router-link>
                          <a v-if="invoice.invoice_status != -1 && $gate.user.permissionsArray.includes('invoices.cancel')" href="#" @click="cancelInvoice(invoice.id)" title="Ακύρωση Παραστατικού">
                            <button class="bg-danger btn btn-sm">
                              <i class="fa fa-times white"></i>
                            </button>
                          </a> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :limit="0" :data="invoices" @pagination-change-page="loadInvoices"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

    </div>
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';

    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                invoices : {},
                form: new Form({
                    id : '',
                    client_sender_id: '',
                    client_issuer_id: '',
                    client_receiver_id: '',
                    client_payer_id: '',
                    from_area_id: '',
                    to_area_id: '',
                    vehicle_id: '',
                    ref_number: '',
                    insurance_cost_percentage: '',
                    insurance_cost: '',
                    vat_percentage: '',
                    sum_cost: '',
                    pay_on_delivery_amount: '',
                    third_party_fare: '',
                    third_party_ref_number: '',
                    payment_type_id: '',
                    invoice_type_id: '',
                    invoice_series_id: '',
                    invoice_prefix: '',
                    invoice_number: '',
                    issue_date: '',
                    qrUrl: ''
                }),
                clients: [],
                clientsWithKeys: {},
                invoiceTypesWithKeys: {},
                vehicles: [],
                vehiclesWithKeys: {},
                areas: [],
                areasWithKeys: [],
                paymentTypesWithKeys: {},

                dateFrom: '',
                dateTo: '',

                doc: [],

                searchClient: '',
                clientsFound: [],
                loadingClients: false,
            }
        },
        methods: {

          loadInvoices(page = 1, filter = '') {
              this.$Progress.start();
              axios.get('/api/invoices?' + filter).then(({ data }) => {
                this.invoices = data.data

                //load correct clients
                data = data.data.data;

                let client_ids = [];
                for (let i = 0; i < data.length; i++)
                {
                  client_ids.push(data[i].client_sender_id);
                  client_ids.push(data[i].client_receiver_id);
                  client_ids.push(data[i].client_issuer_id);
                }

                this.loadClients('?in=' + client_ids.join(','));
              });
              this.$Progress.finish();
          },
          loadClients(filter = ''){
            axios.get("/api/clients/getList" + filter).then(({ data }) => (this.parseClients(data.data)));
          },
          parseClients(data){
            let clients = {};
            for (const i in data)
              clients[data[i]['id']] = data[i]['company_name'];
            this.clients = clients;

            let clientsWithKeys = {};
            for (let i = 0; i < data.length; i++)
              clientsWithKeys[data[i].id] = data[i];
            this.clientsWithKeys = clientsWithKeys;
          },
          loadInvoiceTypes(){
              axios.get("/api/invoiceTypes/getListKeyValue").then(({ data }) => {
                this.invoiceTypesWithKeys = data.data
              });
          },
          loadVehicles(){
              axios.get("/api/vehicles/getList").then(({ data }) => {
                this.vehicles = data.data
                let vehiclesWithKeys = {};
                for (let i = 0; i < data.data.length; i++)
                  vehiclesWithKeys[data.data[i].id] = data.data[i].plate;
                this.vehiclesWithKeys = vehiclesWithKeys;
              });
          },
          loadAreas(){
              axios.get("/api/areas/getListKeyValue").then(({ data }) => {
                this.areasWithKeys = data.data;
              });
          },

          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },
          searchInvoices(){
            if(this.dateFrom != '' && this.dateTo != '')
              this.loadInvoices(1,'limit=0&getItems=1&issue_date_from=' + this.$moment(this.dateFrom).format('yyyy-MM-DD') + '&issue_date_to=' + this.$moment(this.dateTo).format('yyyy-MM-DD'));
          },
          setDateFrom(date = ''){
            if(date != '')
            {
              this.dateFrom = date;
              this.searchInvoices();
            }
          },
          setDateTo(date = ''){
            if(date != '')
            {
              this.dateTo = date;
              this.searchInvoices();
            }
          },

         loadPaymentTypes(){
            axios.get("/api/paymentTypes/getList").then(({ data }) => (this.parsePaymentTypes(data.data)));
          },
          parsePaymentTypes(data){
            let paymentTypesWithKeys = {};
            for (let i = 0; i < data.length; i++)
            {
              paymentTypesWithKeys[data[i].id] = data[i].name;  
            }
            this.paymentTypes = data;
            this.paymentTypesWithKeys = paymentTypesWithKeys;
          },

          //to use for creating pdf here
          preparePDF(invoice){
            let additionalData = {};
            additionalData['clientsWithKeys'] = this.clientsWithKeys;
            additionalData['vehiclesWithKeys'] = this.vehiclesWithKeys;
            additionalData['paymentTypesWithKeys'] = this.paymentTypesWithKeys;
            additionalData['invoiceTypesWithKeys'] = this.invoiceTypesWithKeys;
            return Vue.prototype.$Invoice.preparePDF(invoice, additionalData, this.doc);
          },
          downloadPDF(invoice){
            let pdfName = 'Παραστατικό ' + invoice.invoice_full_number;
            let jsPDF = this.preparePDF(invoice);
            jsPDF.save(pdfName + '.pdf');
          },
          downloadAll(){
            console.log('here');
            console.log(this.invoices.data.length);
            for (let i = 0; i < this.invoices.data.length; i++)
            {
              console.log(this.invoices.data[i]);
              this.doc = this.preparePDF(this.invoices.data[i]);
              console.log(this.doc);
            }
            this.doc.save('Παραστατικά Περιόδου.pdf');
          },

          
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            // this.loadInvoices();
            this.loadInvoiceTypes();
            this.loadVehicles();
            this.loadAreas();
            this.loadPaymentTypes();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
