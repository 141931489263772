<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
            <div class="card" v-if="dataFilter == 'charges'">
              <div class="card-header">
                <h3 class="card-title">Επιλογή ημερομηνιών</h3>
              </div>
              <div class="card-body">

                <form @submit.prevent="searchInvoices()">
                    <div class="row">

                        <div class="col-md-6">
                          <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">
                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία από</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="dateFrom" 
                                    @selected="setDateFrom" 
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">
                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία έως</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="dateTo" 
                                    @selected="setDateTo" 
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>

                </form>

              </div>
            </div>
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Παραστατικών</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Τύπος</th>
                      <th>Περιγραφή</th>
                      <th>Ημερομηνία</th>
                      <th>Ποσό</th>
                      <th v-if="dataFilter == 'charges'">Πίστωση</th>
                      <th>Υπόλοιπο</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="invoice in expenses.data" :key="invoice.type_id + '-' + invoice.id">

                      <td>{{invoice.id}}</td>
                      <td>{{invoice.type}}</td>
                      <td>{{invoice.description}}</td>
                      <td>{{(invoice.issue_date == ''?'':$moment(invoice.issue_date).format('DD/MM/yyyy'))}}</td>
                      <td>{{(invoice.sum !== ''?invoice.sum:0)}}€</td>
                      <td v-if="dataFilter == 'charges'">{{(invoice.credit !== ''?invoice.credit:0)}}€</td>
                      <td>{{(invoice.balance !== ''?invoice.balance:0)}}€</td>
                      <td>
                        <!-- <a target="_blank" :href="'/invoices/edit/' + invoice.id"  v-if="dataFilter != 'charges'">
                            <i class="fa fa-search blue"></i>
                        </a> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="expenses" @pagination-change-page="loadExpenses"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

    </div>
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                id: '',
                editmode: false,
                clients: [],
                expenses : {},
                invoiceTypes: [],
                invoicesData: {},
                paymentsData: {},
                previousBalanceData: {},
                dataFilter: '',
                balance: 0,
                dateFrom: '',
                dateTo: '',
                additionalfilters: [],
            }
        },
        methods: {

          loadExpenses(page = 1) {
              this.$Progress.start();
              axios.get('/api/expenses?page=' + page + '&filter=charges&supplier_id=' + this.id).then(({ data }) => (this.parseExpenses(data)));
              this.$Progress.finish();
          },
          parseExpenses(data) {

            this.expensesData = data.data.expenses.data;
            this.paymentsData = data.data.payments.data;
            this.previousBalanceData = data.data.previous_balance;
            let d = this.prepareCharges();
            this.expenses = {data: d.expenses};

            // this.loadClients('?in=' + client_ids.join(','));
          },
          prepareCharges(){

              //prepare vars
              let client_ids = [];
              let balance = 0;
              let d = [];


              //get all dates
              let dates = [];
              for (let i = 0; i < this.expensesData.length; i++)
                dates.push({'date': this.expensesData[i].expense_date, 'type': 'expense', 'index': i});
              for (let i = 0; i < this.paymentsData.length; i++)
                dates.push({'date': this.paymentsData[i].payment_date, 'type': 'payment', 'index': i});

              //sort them
              dates.sort( this.compare );


              //calculate balance after every invoice and payment
              //add client previous balance
              d.push({id: '', type: 'Προηγούμενο Υπόλοιπο', type_id: 0, invoice_full_number: '', issue_date: '', client_sender_id: '', client_receiver_id: '', sum: this.previousBalanceData, credit: 0, balance: this.previousBalanceData});
              balance = this.calculateCents(this.previousBalanceData);

              for (let i = 0; i < dates.length; i++)
              {
                if(dates[i].type == 'expense')
                {

                  balance += this.calculateCents(this.expensesData[dates[i].index].sum_cost);
                  this.expensesData[dates[i].index].type = 'Έξοδο';
                  this.expensesData[dates[i].index].type_id = 1;
                  this.expensesData[dates[i].index].credit = 0;
                  this.expensesData[dates[i].index].description = this.expensesData[dates[i].index].notes;
                  this.expensesData[dates[i].index].sum = this.expensesData[dates[i].index].sum_cost;
                  this.expensesData[dates[i].index].issue_date = this.expensesData[dates[i].index].expense_date;
                  this.expensesData[dates[i].index].balance = Math.round(balance) / 100;
                  if(this.shouldShow(dates[i].date))
                    d.push(this.expensesData[dates[i].index]);
                }
                else
                {
                  balance -= this.calculateCents(this.paymentsData[dates[i].index].amount);
                  this.paymentsData[dates[i].index].type = 'Πληρωμή';
                  this.paymentsData[dates[i].index].type_id = 2;
                  this.paymentsData[dates[i].index].description = this.paymentsData[dates[i].index].description;
                  this.paymentsData[dates[i].index].issue_date = this.paymentsData[dates[i].index].payment_date;
                  this.paymentsData[dates[i].index].credit = this.paymentsData[dates[i].index].amount;
                  this.paymentsData[dates[i].index].sum = '';
                  this.paymentsData[dates[i].index].amount = '';
                  this.paymentsData[dates[i].index].balance = Math.round(balance) / 100;
                  if(this.shouldShow(dates[i].date))
                    d.push(this.paymentsData[dates[i].index]);
                }
              }

              return {expenses: d, client_ids: client_ids};
          },
          compare( a, b ) {
            if ( a.date < b.date ){
              return -1;
            }
            if ( a.date > b.date ){
              return 1;
            }
            return 0;
          },
          shouldShow(date){
            let shouldShow = true;
            if(typeof this.additionalfilters['dateFrom'] != 'undefined' && this.additionalfilters['dateFrom'].length > 0)
            {
              console.log(this.additionalfilters['dateFrom'], date, this.additionalfilters['dateFrom'] < date);
              if(this.additionalfilters['dateFrom'] > date)
                shouldShow = false;
            }

            if(typeof this.additionalfilters['dateTo'] != 'undefined' && this.additionalfilters['dateTo'].length > 0)
            {
              if(this.additionalfilters['dateTo'] < date)
                shouldShow = false;
            }


            return shouldShow;
          },



          calculateCents(amount){
            if(amount == null)
              return 0;

            amount = parseFloat(amount.toString().replace(',', '.'));
            amount = Math.round(amount * 100);
            if(isNaN(amount))
              amount = 0;

            return amount;
          },
          loadClients(filter = ''){
            axios.get("/api/clients/getList" + filter).then(({ data }) => (this.parseClients(data.data)));
          },
          parseClients(data){
            let clients = {};
            for (const i in data)
              clients[data[i]['id']] = data[i]['company_name'];
            this.clients = clients;
          },



          //dates
          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },
          setDateFrom(date = ''){
            if(date != '' && date != null)
              this.additionalfilters['dateFrom'] = this.$moment(date).format('yyyy-MM-DD');
            else
              this.additionalfilters['dateFrom'] = '';

            this.searchChargesWithFilters();
          },
          setDateTo(date = '' && date != null){
            if(date != '')
              this.additionalfilters['dateTo'] = this.$moment(date).format('yyyy-MM-DD');
            else
              this.additionalfilters['dateTo'] = '';

            this.searchChargesWithFilters();
          },
          searchChargesWithFilters(){
            const d = this.prepareCharges();
            this.invoices = {data: d.invoices};
          },

         
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            //select list
            let parts = this.$route.path.split('/');
            this.dataFilter = parts[2];
            this.id = parseInt(this.$route.params.id);

            if(this.dataFilter == 'charges')
              this.dateFrom = new Date(new Date().getFullYear(), 0, 1);;


            this.loadExpenses();
            
            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
    }
</script>
