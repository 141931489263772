<template>
    <barcode value="value-to-render" format="barcode-format" id="barcode">
        Show this if the rendering fails.
    </barcode>
</template>
<script>
let Vue = require('vue');

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import VueBarcode from 'vue-barcode';


export default {
    components: {
        VueBarcode
        },
        data(){
            return{
                doc: null,
                formData: null,
            }
        },
        preparePDF(formData, additionalData, doc = '')
        {
            //set formData as global variable to use in functions
            this.formData = formData;
            formData = null;

            //Declare new pdf 
            //A4 page size: (x)595.28pt, (y)841.89pt
            if(doc == '')
            {
                this.doc = new jsPDF("p","pt", "a4");
    
                //Declare fonts used`
                this.doc.addFont("/fonts/Roboto/Roboto-Black.ttf", "Roboto", "black");
                this.doc.addFont("/fonts/Roboto/Roboto-Bold.ttf", "Roboto", "bold");
                this.doc.addFont("/fonts/Roboto/Roboto-Light.ttf", "Roboto", "light");
                this.doc.addFont("/fonts/Roboto/Roboto-Medium.ttf", "Roboto", "medium");
                this.doc.addFont("/fonts/Roboto/Roboto-Regular.ttf", "Roboto", "regular");
                this.doc.addFont("/fonts/Roboto/Roboto-Thin.ttf", "Roboto", "thin");
            }
            else
            {
                this.doc = doc;
                this.doc.addPage();
            }

            let a4_x = 595.28;
            let a4_y = 841.89;
  
            //Declare colors used
            var black = "000000";
            var mainAccentColor = "ff7a00";
            var mainTextColor = "323232";
                     
            //Main company logo and information
            let logo_x = 30;
            let logo_y = 30;
            let logo_width = 150;
            let logo_height = 30;
            let company_y = 80;
            let invoiceNumber_y = 115;
            let company_step = 12;
            let company_margin = 22;
            let company_counter = 0;
            //Display Main Company Logo and alter size
            this.doc.addImage("/images/logo.png", "PNG", logo_x, logo_y, logo_width, logo_height);
            //Display Main Company Info  
            this.doc.setTextColor(mainTextColor);
            this.doc.setFontSize(8);
            if (Vue.prototype.$settings.address)
            {
                this.doc.setFont("Roboto", "medium");
                this.doc.text("Έδρα:", logo_x, company_y + (company_step * company_counter));
                this.doc.setFont("Roboto", "regular");
                this.doc.text(Vue.prototype.$settings.address, logo_x + company_margin, company_y + (company_step * company_counter++));
            }
            if (Vue.prototype.$settings.landline) 
            {
                this.doc.setFont("Roboto", "medium");
                this.doc.text("Τηλ:", logo_x, company_y + (company_step * company_counter));
                this.doc.setFont("Roboto", "regular");
                this.doc.text(Vue.prototype.$settings.landline, logo_x + company_margin, company_y + (company_step * company_counter++));
            }
            if (Vue.prototype.$settings.email) 
            {
                this.doc.setFont("Roboto", "medium");
                this.doc.text("Email:", logo_x, company_y + (company_step * company_counter));
                this.doc.setFont("Roboto", "regular");
                this.doc.text(Vue.prototype.$settings.email, logo_x + company_margin, company_y + (company_step * company_counter++));
            }
            if (Vue.prototype.$settings.company_afm) 
            {
                this.doc.setFont("Roboto", "medium");
                this.doc.text("ΑΦΜ:", logo_x, company_y + (company_step * company_counter));
                this.doc.setFont("Roboto", "regular");
                this.doc.text(Vue.prototype.$settings.company_afm, logo_x + company_margin, company_y + (company_step * company_counter++));
            }
            if (Vue.prototype.$settings.company_doy) 
            {
                this.doc.setFont("Roboto", "medium");
                this.doc.text("ΔΟΥ:", logo_x, company_y + (company_step * company_counter));
                this.doc.setFont("Roboto", "regular");
                this.doc.text(Vue.prototype.$settings.company_doy, logo_x + company_margin, company_y + (company_step * company_counter++));
            }      
            
  
            //Header Line
            let headerLineWidth = 7;
            this.doc.setDrawColor(mainAccentColor);
            this.doc.setLineWidth(headerLineWidth);
            this.doc.line(280, logo_y + headerLineWidth, a4_x - logo_x, logo_y + headerLineWidth);
  
            //Display Invoice Info (type, series, number)
            let invoice_y = logo_y + headerLineWidth + 25;
            this.doc.setFont("Roboto", "bold");
            this.doc.setFontSize(20);
            this.doc.setTextColor(black);
            this.doc.text(additionalData['invoiceTypesWithKeys'][this.formData.invoice_type_id], 280, invoice_y); 
            this.doc.setTextColor(mainAccentColor);
            this.doc.text(this.formData.invoice_full_number.toString(), 280, invoice_y + 25);
  
            //Display Issue Date and Time
            //Set locale
            var locale = window.navigator.userLanguage || window.navigator.language;
            //Define Date in the appropriate format
            const formattedDate = new Date(this.formData.issue_date).toLocaleDateString(locale);
            let issue_y = invoiceNumber_y; 
            let issue_x = 280;         
            this.doc.setFont("Roboto", "regular");
            this.doc.setFontSize(9);
            this.doc.setTextColor(mainTextColor);
            this.doc.text("Ημερομηνία Έκδοσης:",  issue_x, issue_y);    
            this.doc.text(formattedDate,  issue_x + 95, issue_y);
            this.doc.text(this.formData.issue_time,  issue_x + 145, issue_y); 
            
            //Display origin destination and vehicle number (where applicable)
            let places_x = 280;
            let places_y = 130;
            this.doc.setFont("Roboto", "regular");
            this.doc.setFontSize(9);
            this.doc.setTextColor(mainTextColor);

            this.doc.text("Τόπος Φόρτωσης:", places_x, places_y);
            if (this.formData.from_area_id)
                this.doc.text(this.formData.from_area_id.toString(), places_x + 95, places_y, {maxWidth: 210});

            this.doc.text("Τόπος Προορισμού:", places_x, places_y + 15);
            if (this.formData.to_area_id)
                this.doc.text(this.formData.to_area_id.toString(), places_x + 95, places_y + 15, {maxWidth: 200});

            if (this.formData.vehicle_id) 
            {
                if (this.formData.invoice_type_id == 1 || this.formData.invoice_type_id == 2 || this.formData.invoice_type_id == 5) 
                {
                    this.doc.text("Αριθμός Οχήματος:", places_x, places_y + 30);
                    this.doc.text(additionalData['vehiclesWithKeys'][this.formData.vehicle_id], places_x + 95, places_y + 30); //need to be coded                
                }
            }
              
            //Display Sender Block Text (Where applicable)
            //Display Line
            if (this.formData.invoice_type_id == 1 || this.formData.invoice_type_id == 2 || this.formData.invoice_type_id == 4 || this.formData.invoice_type_id == 5 ) 
            {

                let sender_x = 30;
                let sender_line_y = 190;
                this.doc.setDrawColor(mainAccentColor);
                this.doc.setLineWidth(4);
                this.doc.line(sender_x, sender_line_y, sender_x, sender_line_y + 90);
                //Display Text     
                this.doc.setFont("Roboto", "bold");
                this.doc.setFontSize(13);
                this.doc.text("ΑΠΟΣΤΟΛΕΑΣ", sender_x + 7, sender_line_y + 10);
                this.doc.setFont("Roboto", "regular");
                //Display Sender
                this.clientDetails(additionalData['clientsWithKeys'][this.formData.client_sender_id], {x: sender_x, y: sender_line_y});
            }

               
            //Display Receiver Block Text
            //Display Line
            let receiver_x = 280;
            let receiver_line_y = 190;
            this.doc.setDrawColor(mainAccentColor);
            this.doc.setLineWidth(4);
            this.doc.line(receiver_x, receiver_line_y, receiver_x, receiver_line_y + 90);
            //Display Text     
            this.doc.setFont("Roboto", "bold");
            this.doc.setFontSize(13);
            this.doc.text("ΠΑΡΑΛΗΠΤΗΣ", receiver_x + 7, receiver_line_y + 10)

            // //Display Receiver
            this.clientDetails(additionalData['clientsWithKeys'][this.formData.client_receiver_id], {x: receiver_x, y: receiver_line_y});

            //Display Issuer Block Text
            //Display Line    
            if (this.formData.invoice_type_id != 7 && this.formData.client_issuer_id) 
            {
                let issuer_x = 30;
                let issuer_line_y = 300;
                this.doc.setDrawColor(mainTextColor);
                this.doc.setLineWidth(4);
                this.doc.line(issuer_x, issuer_line_y, issuer_x, issuer_line_y + 90);
                //Display Text     
                this.doc.setFont("Roboto", "bold");
                this.doc.setFontSize(13);
                this.doc.text("ΕΝΤΟΛΕΑΣ", issuer_x + 7, issuer_line_y + 10)
                
                this.clientDetails(additionalData['clientsWithKeys'][this.formData.client_issuer_id], {x: issuer_x, y: issuer_line_y});
            }
            
            //Display Main Table
            //Declare Columns and 
            var columns = [
                {title: "Ποσότητα", dataKey: "quantity"},
                {title: "Περιγραφή Αγαθών", dataKey: "quantity_type"},
                {title: "Βάρος", dataKey: "weights"},
                {title: "Κόστος", dataKey: "initial_costs"},
            ];


            let data = [];
            for (let i = 1; i < this.formData.quantities.length; i++)
            {
                if(!(this.formData.quantities[i] > 0))
                    continue;
                let d = {"quantity": this.formData.quantities[i], "quantity_type": this.formData.quantity_types[i], "weights": this.formData.weights[i], "initial_costs": this.formData.initial_costs[i] + "€"};
                data.push(d);
            }

            this.doc.autoTable(
            {
                columns: (this.formData.invoice_type_id == 7?[columns[0], columns[1]]:columns),
                body: data,
                headStyles: {font: "Roboto", halign: "center", fillColor: mainAccentColor},
                bodyStyles: {font: "Roboto", fontSize: 9, fontStyle: "regular", halign: "center", textcolor: mainTextColor },
                startY: (this.formData.invoice_type_id == 7?350:400),
                margin: {horizontal: 28},
            });
  
            //Display Detailed Costs
            if (this.formData.invoice_type_id == 1 || this.formData.invoice_type_id == 2 || this.formData.invoice_type_id == 4 || this.formData.invoice_type_id == 5 || this.formData.invoice_type_id == 6 ) 
            {
                //σε περίπτωση που δεν είναι υπολογισμένα όλα τα κόστη
                if(typeof this.formData.partial_cost == 'undefined')
                    this.calculateSums();

                let costs_x = 400;
                let costs_y = 600;
                let costs_distance = 15;
                let costs_counter = 0;
                let costs_line_y = costs_y + 5;
                let costs_line_distance_y = 30;
                let costs_line_counter = 0;

                this.doc.setDrawColor(mainTextColor);
                this.doc.setLineWidth(0.5);
                this.doc.setLineDashPattern([1, 1]);
                this.doc.setFont("Roboto", "bold");
                this.doc.setFontSize(9);

                this.doc.text("ΚΑΘΑΡΗ ΑΞΙΑ", costs_x, costs_y + (costs_distance * costs_counter));
                this.doc.text(this.formData.partial_cost.toString() + "€", costs_x + 110, costs_y + (costs_distance * costs_counter++));
                this.doc.line(costs_x, costs_line_y + (costs_line_distance_y * costs_line_counter), a4_x - 40, costs_line_y + (costs_line_distance_y * costs_line_counter++));

                this.doc.text("ΑΣΦΑΛΙΣΤΡΑ", costs_x, costs_y + (costs_distance * costs_counter));
                this.doc.text(this.formData.insurance_cost_percentage.toString() + "%", costs_x + 57, costs_y + (costs_distance * costs_counter));
                this.doc.text(this.formData.insurance_cost.toString() + "€", costs_x + 110, costs_y + (costs_distance * costs_counter++));

                this.doc.text("Φ.Π.Α", costs_x, costs_y + (costs_distance * costs_counter));
                this.doc.text(this.formData.vat_percentage.toString() + "%", costs_x + 28, costs_y + (costs_distance * costs_counter));
                this.doc.text(this.formData.vat_cost.toString() + "€", costs_x + 110, costs_y + (costs_distance * costs_counter++));
                this.doc.line(costs_x, costs_line_y + (costs_line_distance_y * costs_line_counter), a4_x - 40, costs_line_y + (costs_line_distance_y * costs_line_counter++));

                this.doc.text("ΑΝΤΙΚΑΤΑΒΟΛΗ", costs_x, costs_y + (costs_distance * costs_counter));
                if (this.formData.pay_on_delivery_amount) 
                    this.doc.text(this.formData.pay_on_delivery_amount.toString() + "€", costs_x + 110, costs_y + (costs_distance * costs_counter++));
                else
                    this.doc.text("0.00"  + "€", costs_x + 110, costs_y + (costs_distance * costs_counter++));

                this.doc.text("ΚΟΜΙΣΤΡΑ ΤΡΙΤΩΝ", costs_x, costs_y + (costs_distance * costs_counter));
                if (this.formData.third_party_fare) 
                    this.doc.text(this.formData.third_party_fare.toString() + "€", costs_x + 110, costs_y + (costs_distance * costs_counter++))
                else 
                    this.doc.text("0.00" + "€", costs_x + 110, costs_y + (costs_distance * costs_counter++));
                    
                this.doc.line(costs_x, costs_line_y + (costs_line_distance_y * costs_line_counter), a4_x - 40, costs_line_y + (costs_line_distance_y * costs_line_counter++));

                this.doc.text("ΠΛΗΡΩΤΕΟ", costs_x, costs_y + (costs_distance * costs_counter))
                this.doc.text(this.formData.sum_cost.toString() + "€", costs_x + 110, costs_y + (costs_distance * costs_counter++));              //form.sum_cost
            }


            //Display Reference Numbers
            if (this.formData.ref_number) 
            {
                this.doc.setFont("Roboto", "regular");
                let ref_x = 30;
                let ref_y = 600;
                this.doc.text("Αριθμοί Παραστατικών: " + this.formData.ref_number, ref_x, ref_y, {maxWidth: 280});
            }

            //Display Notes
            if (this.formData.notes) 
            {
                let notes_x = 30;
                let notes_y = 650;
                this.doc.text("Παρατηρήσεις: " + this.formData.notes, notes_x, notes_y, {maxWidth: 280});  
            }

            //Display Client Charged
            if (this.formData.invoice_type_id == 1 || this.formData.invoice_type_id == 2 || this.formData.invoice_type_id == 4 || this.formData.invoice_type_id == 5) 
            {
                this.doc.setFont("Roboto", "bold");
                this.doc.text ("Χρέωση: " + this.formData.client_charged.toString(), 400, 695); //needs to be coded
                this.doc.text("Τρόπος Πληρωμής: " + additionalData['paymentTypesWithKeys'][this.formData.payment_type_id], 400, 710);
            }

            //Display Footer
            this.doc.setDrawColor(mainTextColor);
            this.doc.setLineWidth(0.5);
            this.doc.setLineDashPattern([4, 2]);
            this.doc.line(30, 720, 560, 720);

            if(this.formData.qrUrl != null && this.formData.qrUrl.length > 0)
            {
                console.log('here');
                console.log(this.formData.qrUrl);
                const qrCodeElement = document.createElement('DIV');
                const qr = new QRCode(qrCodeElement, {
                    text: this.formData.qrUrl,
                    width: 256,
                    height: 256,
                    colorDark : "#000000",
                    colorLight : "#ffffff",
                    correctLevel : QRCode.CorrectLevel.L
                });
                let invoice_qrCode = qr._oDrawing._elCanvas.toDataURL("image/png");
                this.doc.addImage(invoice_qrCode, "PNG", 365, 730, 65, 65);
            }

            if(this.formData.id > 0)
            {
                const barcode = document.createElement('CANVAS');
                JsBarcode(barcode, String(this.formData.id).padStart(7, '0'), {
                    format: "EAN8",
                    text: "code"
                });
                const base64Image = barcode.toDataURL();
                this.doc.addImage(base64Image, "PNG", 240, 730, 90, 50);
            }

            //Company Info Footer
            if (this.formData.invoice_type_id != 7)
            {  
                this.doc.setTextColor(mainTextColor);
                
                this.doc.setFontSize(8);
                if(Vue.prototype.$settings.footer)
                    this.doc.text (Vue.prototype.$settings.footer, 30, 730);
                if(Vue.prototype.$settings.footer1)
                    this.doc.text (Vue.prototype.$settings.footer1, 210, 730);
                
                this.doc.setFont("Roboto", "medium");
                this.doc.setFontSize(10);
                this.doc.text("Ο ΠΑΡΑΛΗΠΤΗΣ",  482.64, 735, "center");
            }

            //Display Δελτίο Αποστολής Footer
            if (this.formData.invoice_type_id == 7) 
            {
                //Display Issue, Delivery, Receipt
                this.doc.setFont("Roboto", "medium");
                this.doc.setFontSize(12);
                this.doc.text("ΕΚΔΟΣΗ", 30, 735);
                this.doc.text("ΠΑΡΑΔΟΣΗ", 260 ,735,)
                this.doc.text("ΠΑΡΑΛΑΒΗ", 497 ,735,)
            }
            return this.doc;           
        },
        clientDetails(client, coordinates){

            let y = 0,
                y_start = 40,
                y_step = 11;

            //Display Name
            this.doc.setFontSize(10)
            this.doc.text(client.full_name, coordinates.x + 7, coordinates.y + 25 , {maxWidth: 272});
            y++;

            //Display Address
            this.doc.setFontSize(8)
            if(client['address'] != null && typeof client['address'] != 'undefined')
            {
                this.doc.text(client.address, coordinates.x + 7, coordinates.y + y_start, {maxWidth: 272});
                y++;
            }

            //Display AFM
            if(client['company_afm'] != null && typeof client['company_afm'] != 'undefined')
            {
                this.doc.setFont("Roboto", "medium");
                this.doc.text("ΑΦΜ:", coordinates.x + 7, coordinates.y + (y_start + (y_step * y)));

                this.doc.setFont("Roboto", "regular");
                this.doc.text(client.company_afm, coordinates.x + 30, coordinates.y + (y_start + (y_step * y)));
                y++;
            }

            //Display DOY
            if(client['company_doy'] != null && typeof client['company_doy'] != 'undefined')
            {
                this.doc.setFont("Roboto", "medium");
                this.doc.text("ΔΟΥ:", coordinates.x + 7, coordinates.y + (y_start + (y_step * y)));

                this.doc.setFont("Roboto", "regular");
                this.doc.text(client.company_doy, coordinates.x + 30, coordinates.y + (y_start + (y_step * y))); //needs to be coded
                y++;
            }

            //Display Sender Landline and Mobile Label
            if(client['landline'] != null && typeof client['landline'] != 'undefined')
            {
                this.doc.setFont("Roboto", "medium");
                this.doc.text("Τηλ:", coordinates.x + 7, coordinates.y + (y_start + (y_step * y)));

                this.doc.setFont("Roboto", "regular");
                this.doc.text(client.landline, coordinates.x + 30, coordinates.y + (y_start + (y_step * y)));
                y++;
            }

        },
        calculateCents(amount){
            if(amount == null)
              return 0;

            amount = parseFloat(amount.toString().replace(',', '.'));
            amount = Math.round(amount * 100);
            if(isNaN(amount))
              amount = 0;

            return amount;
        },
        calculateSums(){
            let partial_cost = 0;
            for (let i = 1; i < this.formData.initial_costs.length; i++)
            {
                let cost = 0;
                try{
                    let cost = parseFloat(this.formData.initial_costs[i].toString().replace(',', '.'));
                    cost = Math.round(cost * 100)  / 100
                    if(cost > 0)
                      partial_cost += cost;
                }
                catch{}
            }
            this.formData.partial_cost = partial_cost;

            //για να δουλεύουμε μόνο με ακέραιους
            this.formData.insurance_cost = parseInt(this.calculateCents(this.formData.partial_cost) * parseInt(this.formData.insurance_cost_percentage) / 100) / 100;
            this.formData.net_cost = (this.calculateCents(this.formData.partial_cost) + this.calculateCents(this.formData.insurance_cost)) / 100;
            this.formData.vat_cost = this.calculateCents((this.calculateCents(this.formData.net_cost) * parseInt(this.formData.vat_percentage)) / 10000) / 100;
            this.formData.sum = (this.calculateCents(this.formData.vat_cost) + this.calculateCents(this.formData.net_cost)) / 100;
            this.formData.sum_cost = (this.calculateCents(this.formData.sum) + this.calculateCents(this.formData.pay_on_delivery_amount) + this.calculateCents(this.formData.third_party_fare)) / 100;
          },
 }

</script>