<template>
  <section class="content">
    <div class="container-fluid" data-app>
        <div class="row">
          <div class="col-12">


            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Φόρμα Αναζήτησης</h3>
              </div>
              <div class="card-body">

                <form @submit.prevent="searchClients()">
                    <div class="row">

                        <div class="col-md-4">
                            <v-autocomplete
                                label="Πελάτης"
                                item-text="company_name"
                                item-value="id"
                                v-model="search_client_id"
                                dense
                                placeholder="Πληκτρολογήστε Ονομασία / ΑΦΜ / Τηλέφωνο για αναζήτηση"
                                :items="clientsFound"
                                :loading="loadingClients"
                                :search-input.sync="searchClient"
                                clearable
                                @keypress="searchClients()"
                                @change="loadSearchedInvoices()"
                            ></v-autocomplete>
                        </div>

                        <div class="col-md-4">
                            <v-text-field
                                label="Αρ. Παραστατικού"
                                v-model="search_invoice_full_number"
                                dense
                                clearable
                                placeholder="Πληκτρολογήστε τον αριθμό παραστατικού"
                                @change="loadSearchedInvoices()"
                            ></v-text-field>
                        </div>

                        <div class="col-md-4">
                          <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. έκδοσης</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_issue_date" 
                                    @selected="loadSearchedInvoices" 
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>

                </form>

              </div>
            </div>
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Παραστατικών</h3>

                <div class="card-tools">

                  <button type="button" class="btn btn-sm bg-primary white" @click="openSendModal()">
                      <i class="fa fa-paper-plane"></i>
                      Αποστολή μηνυμάτων
                  </button>

                  <router-link :to="{ path: '/invoices/download' }" v-if="$gate.user.permissionsArray.includes('invoices.index')">
                    <button type="button" class="btn btn-sm bg-primary white">
                        <i class="fa fa-download"></i>
                        Λήψη παραστατικών
                    </button>
                  </router-link>
                  
                  <router-link :to="{ path: '/invoices/edit' }" v-if="$gate.user.permissionsArray.includes('invoices.edit')">
                    <button type="button" class="btn btn-sm bg-primary white">
                        <i class="fa fa-plus-square"></i>
                        Έκδοση Νέου
                    </button>
                  </router-link>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Είδος Παραστ.</th>
                      <th>Αρ. Παραστ.</th>
                      <th>Αποστολέας</th>
                      <th>Παραλήπτης</th>
                      <th>Χρέωση σε</th>
                      <th>Ημ. Έκδοσης</th>
                      <th>Ποσό</th>
                      <th>Υπόλοιπο</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="invoice in invoices.data" :key="invoice.id">

                      <td>{{invoice.id}}</td>
                      <td>{{invoiceTypes[invoice.invoice_type_id]}}</td>
                      <td>{{invoice.invoice_full_number}}</td>
                      <td>{{(typeof clients[invoice.client_sender_id] != 'undefined'?clients[invoice.client_sender_id]:invoice.client_sender_id)}}</td>
                      <td>{{(typeof clients[invoice.client_receiver_id] != 'undefined'?clients[invoice.client_receiver_id]:invoice.client_receiver_id)}}</td>
                      <td>{{(typeof clients[invoice.client_charged_id] != 'undefined'?clients[invoice.client_charged_id]:invoice.client_charged_id)}}</td>
                      <td>{{$moment(invoice.issue_date).format('DD/MM/yyyy')}} {{invoice.issue_time}}</td>
                      <td>{{(invoice.sum_cost !== ''?invoice.sum_cost:0)}}€</td>
                      <td>{{(invoice.balance !== ''?invoice.balance:0)}}€</td>
                      <td>
                        <div class="row pt-3">
                          <!-- <button v-show="editmode" type="button" class="btn btn-primary" @click="downloadPDF(invoice)"><i class="fa fa-download white"></i></button> -->
                          <router-link :to="{ path: '/invoices/edit/' + invoice.id }">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία και Εκτύπωση Παραστατικού">
                              <i class="fa fa-edit white"></i>
                            </button>
                          </router-link>
                          <a v-if="invoice.invoice_status != -1 && $gate.user.permissionsArray.includes('invoices.cancel')" href="#" @click="cancelInvoice(invoice.id)" title="Ακύρωση Παραστατικού">
                            <button class="bg-danger btn btn-sm">
                              <i class="fa fa-times white"></i>
                            </button>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :limit="12" :data="invoices" @pagination-change-page="loadInvoices"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="scanBarcodes" tabindex="-1" role="dialog" aria-labelledby="scanBarcodes" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Αποστολή μηνυμάτων</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="sendMessage()">
                    <div class="modal-body">

                      <div class="row">
                        <div class="col-12">
                          <v-text-field
                              label="Σκανάρετε το barcode του τιμολογίου"
                              ref="chargeAddFirstInput"
                              v-model="chargeForm.barcode"
                              v-on:keydown.enter.prevent="addChargeFromInput($event)"
                              :class="{ 'is-invalid': chargeForm.errors.has('barcode') }"
                          ></v-text-field>
                          <has-error :form="chargeForm" field="barcode"></has-error>
                        </div>
                      </div>

                      <div class="col-12 chargeBarcodeBox mb-3" :key="'c-' + invoiceUpdateKey" ref="chargeBarcodeBox">
                        <div v-for="(item, index) in chargeForm.items" :key="'b-' + index">
                          <div class="float-left mr-3">{{ item }}</div>
                          <a href="#" @click="deleteFromChargesArray(index)">
                            <button type="button" class="bg-danger btn btn-sm" title="Διαγραφή παραστατικού">
                                <i class="fa fa-times white"></i>
                            </button>
                          </a>
                        </div>
                      </div>

                      <div>
                        <v-textarea
                          label="Μήνυμα"
                          v-model="chargeForm.viberMessage"
                        >

                        </v-textarea>
                      </div>



                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Κλείσιμο</button>
                        <button type="submit" class="btn btn-primary">Αποστολή μηνύματος</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>

    </div>
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';

    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                editmode: false,
                invoices : {},
                chargeForm: new Form({
                    items: [],
                    barcode: '',
                    viberMessage: '',
                }),
                form: new Form({
                    id : '',
                    client_sender_id: '',
                    client_issuer_id: '',
                    client_receiver_id: '',
                    client_payer_id: '',
                    from_area_id: '',
                    to_area_id: '',
                    vehicle_id: '',
                    ref_number: '',
                    insurance_cost_percentage: '',
                    insurance_cost: '',
                    vat_percentage: '',
                    sum_cost: '',
                    pay_on_delivery_amount: '',
                    third_party_fare: '',
                    third_party_ref_number: '',
                    payment_type_id: '',
                    invoice_type_id: '',
                    invoice_series_id: '',
                    invoice_prefix: '',
                    invoice_number: '',
                    issue_date: ''
                }),
                clients: [],
                clientsWithKeys: {},
                vehiclesWithKeys: {},
                invoiceTypes: [],
                invoiceTypesWithKeys: {},
                areas: [],
                paymentTypesWithKeys: {},



                search_client_id: '',
                search_invoice_full_number: '',
                search_issue_date: '',

                searchClient: '',
                clientsFound: [],
                loadingClients: false,
                invoiceUpdateKey: 0,
            }
        },
        methods: {

          loadInvoices(page = 1, filter = '') {
              this.$Progress.start();
              axios.get('api/invoices?page=' + page + filter).then(({ data }) => {
                this.invoices = data.data

                //load correct clients
                data = data.data.data;

                let client_ids = [];
                for (let i = 0; i < data.length; i++)
                {
                  client_ids.push(data[i].client_sender_id);
                  client_ids.push(data[i].client_receiver_id);
                  client_ids.push(data[i].client_issuer_id);
                }

                this.loadClients('?in=' + client_ids.join(','));
              });
              this.$Progress.finish();
          },
          loadClients(filter = ''){
            axios.get("/api/clients/getList" + filter).then(({ data }) => (this.parseClients(data.data)));
          },
          parseClients(data){
            let clients = {};
            for (const i in data)
              clients[data[i]['id']] = data[i]['company_name'];
            this.clients = clients;

            // let clientsWithKeys = {};
            // for (let i = 0; i < data.length; i++)
            //   clientsWithKeys[data[i].id] = data[i];
            // this.clientsWithKeys = clientsWithKeys;
          },
          loadInvoiceTypes(){
              axios.get("/api/invoiceTypes/getListKeyValue").then(({ data }) => {
                this.invoiceTypes = data.data
                let invoiceTypesWithKeys = {};
                for (let i = 0; i < data.length; i++)
                  invoiceTypesWithKeys[data[i].id] = data[i].name;
                this.invoiceTypesWithKeys = invoiceTypesWithKeys;
              });
          },

          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },
          searchClients(){
            if(this.searchClient.length == 4)
            {
              this.loadingClients = true;
              axios.get("/api/clients/getList?search="+this.searchClient).then(({ data }) => (this.clientsFound = data.data));
              this.loadingClients = false;
            }
          },
          loadSearchedInvoices(date = ''){

              if(date != '')
                this.search_issue_date = date;

              //search all fields
              let searchFields = ['search_client_id', 'search_invoice_full_number', 'search_issue_date'];
              let filter = [];
              let data = '';
              for (let i = 0; i < searchFields.length; i++)
              {
                if(this[searchFields[i]] != null && this[searchFields[i]] != '')
                {
                  if(searchFields[i] == 'search_issue_date')
                    data =  this.$moment(this[searchFields[i]]).format('yyyy-MM-DD');
                  else
                    data =  this[searchFields[i]];

                  filter.push(searchFields[i] + '=' + data);
                }
              }

              let filterText = '';
              if(filter.length > 0)
                filterText = '&' + filter.join('&');
                
              this.loadInvoices(1, filterText);
          },

         loadPaymentTypes(){
            axios.get("/api/paymentTypes/getList").then(({ data }) => (this.parsePaymentTypes(data.data)));
          },
          parsePaymentTypes(data){
            let paymentTypesWithKeys = {};
            for (let i = 0; i < data.length; i++)
            {
              paymentTypesWithKeys[data[i].id] = data[i].name;  
            }
            this.paymentTypes = data;
            this.paymentTypesWithKeys = paymentTypesWithKeys;
          },




          //to use for creating pdf here
          async preparePDF(){

            //load vehicles
            await axios.get("/api/vehicles/getList").then(({ data }) => {
              let vehiclesWithKeys = {};
              for (let i = 0; i < data.data.length; i++){
                  vehiclesWithKeys[data.data[i].id] = data.data[i].plate;
              }
              this.vehiclesWithKeys = vehiclesWithKeys;
            });

            //load payment types
            await axios.get("/api/paymentTypes/getList").then(({ data }) => {
              let paymentTypesWithKeys = {};
              for (let i = 0; i < data.data.length; i++)
              {
                paymentTypesWithKeys[data.data[i].id] = data.data[i].name;  
              }
              this.paymentTypes = data.data;
              this.paymentTypesWithKeys = paymentTypesWithKeys;
            });

            let additionalData = {};
            additionalData['clientsWithKeys'] = this.clients;
            additionalData['vehiclesWithKeys'] = this.vehiclesWithKeys;
            additionalData['paymentTypesWithKeys'] = this.paymentTypesWithKeys;
            additionalData['invoiceTypesWithKeys'] = this.invoiceTypesWithKeys;
            return Vue.prototype.$Invoice.preparePDF(this.form, additionalData);
          },
          downloadPDF(){
            let pdfName = 'Παραστατικό ' + this.form.invoice_full_number;
            let jsPDF = this.preparePDF();
            jsPDF.save(pdfName + '.pdf');
          },

          cancelInvoice(id){
              Swal.fire({
                  title: 'Ακύρωση Παραστατικού',
                  text: "Είστε σίγουροι ότι θέλετε να ακυρώσετε αυτό το παραστατικό; Η ενέργεια αυτή δεν είναι αναστρέψιμη.",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Ναι',
                  cancelButtonText: 'Όχι'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              axios.get("/api/invoices/cancel/" + id).then(()=>{
                              // this.form.cancel('/api/invoices/'+id).then(()=>{
                                      Swal.fire(
                                      'Ακύρωση Παραστατικού',
                                      'Το παραστατικό ακυρώθηκε.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadInvoices();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

          openSendModal(){
            this.chargeForm.reset();
            $('#scanBarcodes').modal('show');
          },

          //bulk insert coupons
          addChargeFromInput(){
            let content = this.chargeForm.barcode.trim();
            if(content == '')
              return;

            if(this.activeChargeEntry == -1)
              this.activeChargeEntry = 0;

            content = parseInt(content.substring(0, content.length -1));
            if(this.chargeForm.items.includes(content))
            {
              this.chargeForm.barcode = '';
              return;
            }


            console.log(content);

            this.addCouponsFeedback = '';
            // this.chargeForm.items[] = content;

            axios.get("/api/getIfInvoiceIdExists/" + content).then(({ data }) => {
              if(typeof data == 'object')
              {
                this.chargeForm.items.push(content);
                this.invoiceUpdateKey++;
              }
            });

            this.chargeForm.barcode = '';

            return;
          },

          deleteFromChargesArray(index){
            this.chargeForm.items.splice(index, 1);
            this.couponUpdateKey++;
            this.activeChargeEntry = this.chargeForm.items.length;
            // if(this.activeChargeEntry >= 0)
            //   this.activeChargeEntry++;
            this.focusOnFirstInput('chargeAddFirstInput');
          },

          focusOnFirstInput(t, wait=0){
            setTimeout(() => {
                let refItem = this.$refs[t];
                refItem.focus();
              }, (wait == 0?Vue.prototype.$waitForFirstInputFocus:300));
          },

          sendMessage(){
            this.chargeForm.post('/api/invoices/sendMessage')
              .then((data)=>{
                if(data.data.success){
                  $('#scanBarcodes').modal('hide');
                }
              });
          }

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadInvoices();
            // this.loadClients();
            this.loadInvoiceTypes();

            //this.loadVehicles();
            //this.loadAreas();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
