<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Σειρών Παραστατικών</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary text-light" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Προσθήκη σειράς
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Τύπος</th>
                      <th>Σειρά</th>
                      <th>Αρίθμιση Από</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="invoiceSerie in invoiceSeries.data" :key="invoiceSerie.id">

                      <td>{{invoiceSerie.id}}</td>
                      <td>{{invoiceTypesWithKeys[invoiceSerie.invoice_type_id]}}</td>
                      <td>{{invoiceSerie.name}}</td>
                      <td>{{invoiceSerie.start_number}}</td>
                      <td>
                        
                        <a href="#" @click="editModal(invoiceSerie)">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία Σειράς">
                              <i class="fa fa-edit"></i>
                            </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="invoiceSeries" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Δημιουργία Σειράς</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία Σειράς</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateInvoiceSeries() : createInvoiceSeries()" data-app>
                    <div class="modal-body">
                        <div class="row">
                          <div class="form-group col-12">
                              <v-select
                                :items="invoiceTypes"
                                label="Τύπος παραστατικού"
                                item-text="name"
                                item-value="id"
                                v-model="form.invoice_type_id"
                              ></v-select>
                            <has-error :form="form" field="invoice_type_id"></has-error>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-6 d-inline-block">
                            <v-text-field
                              label="Σειρά"
                              v-model="form.name"
                              required="true"
                            ></v-text-field>
                            <has-error :form="form" field="name"></has-error>
                          </div>
                          <div class="form-group col-md-6 d-inline-block">
                            <v-text-field
                              label="Το πρώτο παραστατικό θα έχει αριθμό"
                              v-model="form.start_number"
                            ></v-text-field>
                            <has-error :form="form" field="start_number"></has-error>
                          </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Κλείσιμο</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Δημιουργία</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {
                editmode: false,
                form: new Form({
                    id : '',
                    invoice_type_id : '',
                    name: '',
                    start_number: 1
                }),
                invoiceSeries: {},
                invoiceTypes: [],
                invoiceTypesWithKeys: {},
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {

          getResults(page = 1) {

              this.$Progress.start();
              axios.get('/api/invoiceSeries?page=' + page).then(({ data }) => (this.invoiceSeries = data.data));
              this.$Progress.finish();
          },
          loadInvoiceSeries(){

            // if(this.$gate.isAdmin()){
              axios.get("/api/invoiceSeries").then(({ data }) => (this.invoiceSeries = data.data));
            // }
          },
          loadInvoiceTypes(){
              axios.get("/api/invoiceTypes/getList").then(({ data }) => {
                let d = data.data;
                let invoiceTypesWithKeys = {};
                for (let i = 0; i < d.length; i++)
                {
                  invoiceTypesWithKeys[d[i].id] = d[i].name;
                }
                this.invoiceTypesWithKeys = invoiceTypesWithKeys;


                //αφαίρεση ειδικού ακυρωτικού στοιχείου
                for (let i = 0; i < d.length; i++)
                {
                  if(d[i].id == 4)
                    d.splice(i, 1);              
                }
                this.invoiceTypes = d;

              });
          },

          editModal(invoiceSeries){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(invoiceSeries);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createInvoiceSeries(){
              this.$Progress.start();

              this.form.post('/api/invoiceSeries')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadInvoiceSeries();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateInvoiceSeries(){
              this.$Progress.start();
              this.form.put('/api/invoiceSeries/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadInvoiceSeries();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          // deleteProduct(id){
          //     Swal.fire({
          //         title: 'Are you sure?',
          //         text: "You won't be able to revert this!",
          //         showCancelButton: true,
          //         confirmButtonColor: '#d33',
          //         cancelButtonColor: '#3085d6',
          //         confirmButtonText: 'Yes, delete it!'
          //         }).then((result) => {

          //             // Send request to the server
          //               if (result.value) {
          //                     this.form.delete('api/invoiceSeries/'+id).then(()=>{
          //                             Swal.fire(
          //                             'Deleted!',
          //                             'Your file has been deleted.',
          //                             'success'
          //                             );
          //                         // Fire.$emit('AfterCreate');
          //                         this.loadInvoiceSeries();
          //                     }).catch((data)=> {
          //                         Swal.fire("Failed!", data.message, "warning");
          //                     });
          //               }
          //         })
          // },

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadInvoiceSeries();
            this.loadInvoiceTypes();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
