<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
                <div class="modal-header">
                    <h5 class="modal-title">Γενικές Ρυθμίσεις</h5>
                </div>

                <form @submit.prevent="saveSettings()">
                    <div class="modal-body">
                        <div class="row my-0">
                            <div class="form-group col-12 my-0">
                                <h5 class="my-0">Στοιχεία εταιρίας</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="ΑΦΜ"
                                    v-model="form.company_afm"
                                    @keyup="getCompanyDetails"
                                ></v-text-field>
                                <has-error :form="form" field="company_afm"></has-error>
                            </div>
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="Επωνυμία"
                                    v-model="form.company_name"
                                ></v-text-field>
                                <has-error :form="form" field="company_name"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="Επάγγελμα"
                                    v-model="form.company_occupation"
                                ></v-text-field>
                                <has-error :form="form" field="company_occupation"></has-error>
                            </div>
                            <div class="form-group col-md-6">
                                <v-combobox
                                    v-model="form.company_doy"
                                    :items="doys"
                                    label="ΔΟΥ"
                                    :search-input.sync="searchDoy"
                                    type="text"
                                ></v-combobox>
                                <has-error :form="form" field="company_doy"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="Διεύθυνση"
                                    v-model="form.address"
                                ></v-text-field>
                                <has-error :form="form" field="address"></has-error>
                            </div>
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="Τηλέφωνο"
                                    v-model="form.landline"
                                ></v-text-field>
                                <has-error :form="form" field="landline"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="ΦΑΞ"
                                    v-model="form.fax"
                                ></v-text-field>
                                <has-error :form="form" field="fax"></has-error>
                            </div>

                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="Email"
                                    v-model="form.email"
                                ></v-text-field>
                                <has-error :form="form" field="email"></has-error>
                            </div>
                        </div>

                        <hr>
                        <br>

                        <div class="row my-0">
                            <div class="form-group col-12 my-0">
                                <h5 class="my-0">Ρυθμίσεις Παραστατικού</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="Θέμα Email Αποστολής Παραστατικού"
                                    v-model="form.invoice_email_subject"
                                ></v-text-field>
                                <has-error :form="form" field="footer"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-textarea
                                    label="Κείμενο Email Αποστολής Παραστατικού"
                                    v-model="form.invoice_email_text"
                                ></v-textarea>
                                <has-error :form="form" field="footer"></has-error>
                            </div>
                            <div class="form-group col-md-6">
                                <v-textarea
                                    label="Υποσέλιδο Παραστατικού 1 (πχ. εδρα, τράπεζες)"
                                    v-model="form.footer"
                                ></v-textarea>
                                <has-error :form="form" field="footer"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-textarea
                                    label="Υποσέλιδο Παραστατικού 2 (πχ. εδρα, τράπεζες)"
                                    v-model="form.footer1"
                                ></v-textarea>
                                <has-error :form="form" field="footer1"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-checkbox
                                    v-model="form.empty_series"
                                    label="Κενή Σειρά"
                                    :true-value="1" 
                                    :false-value="0"
                                >
                                </v-checkbox>
                            </div>
                            <div class="form-group col-md-6">
                                <v-checkbox
                                    v-model="form.calculate_additional_fees"
                                    label="Υπολογισμός Αντικαταβολής και κόμιστρων τρίτων"
                                    :true-value="1" 
                                    :false-value="0"
                                >
                                </v-checkbox>
                            </div>
                        </div>

                        <hr>

                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="AADE User Id "
                                    v-model="form.aade_user_id"
                                ></v-text-field>
                            </div>
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="AADE Subscription Key"
                                    v-model="form.ocp_apim_subscription_key"
                                ></v-text-field>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success">Update</button>
                    </div>
                  </form>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                settings : {},
                form: new Form({
                    company_name: '',
                    company_occupation: '',
                    address: '',
                    company_afm: '',
                    company_doy: '',
                    company_doy_id: '',
                    landline: '',
                    fax: '',
                    email: '',
                    footer: '',
                    footer1: '',
                    invoice_email_subject: '',
                    invoice_email_text: '',
                    empty_series: '',
                    calculate_additional_fees: '',
                    aade_user_id: '',
                    ocp_apim_subscription_key: '',
                }),
                searchDoy: '',
                doys: [],
                doysWithKeys: [],
            }
        },
        methods: {

          loadSettings() {
              this.$Progress.start();
              axios.get('/api/settings').then(({ data }) => (this.parseSettings(data.data)));
              this.$Progress.finish();
          },
          parseSettings(data){
              this.settings = data;
              this.form.fill(data);
              this.loadDoys();
          },
          loadDoys(){
              //get without ids for combobox
              axios.get("/api/doys/list").then(({ data }) => (this.parseDoys(data.data)));
          },
          parseDoys(data){
              let doysArray = [];
              for (const id in data)
                  doysArray.push(data[id]);

              this.doysWithKeys = data;
              this.doys = doysArray;

              let doy = this.doysWithKeys[String(this.company_doy_id)];
              if(typeof doy != 'undefined')
                this.form.company_doy = doy;
          },
          getCompanyDetails(){
              if(this.form.company_afm.length == 9)
                axios.get("/api/invoices/getCompanyDetails/" + this.form.company_afm).then(({ data }) => (this.setCompanyDetails(data.data)));
          },
          setCompanyDetails(data){
              var data = JSON.parse(data);
              this.form.company_name = data.fullName;
              this.searchDoy = data.doyDescr;
              this.form.company_doy = this.searchDoy;
              this.form.address = data.addressFull;
              this.form.company_occupation = data.kad.base[Object.keys(data.kad.base)[0]];
          },
          saveSettings(){
              this.$Progress.start();

              //send combobox value if no doy is selected
              if((typeof this.form.company_doy_id == 'undefined' || this.form.company_doy_id == "") && (this.searchDoy && this.searchDoy.length > 0))
                  this.form.company_doy_id = this.searchDoy;

              this.form.post('/api/settings')
              .then((data)=>{
                if(data.data.success){

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    
                  //refresh global setting variable
                  axios.get("/api/invoices/getPlatformSettings").then(({ data }) => (Vue.prototype.$settings = data.data));
                  
                  this.$Progress.finish();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();
            this.loadSettings();
            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
