<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">User List</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Email Verified?</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="user in users.data" :key="user.id">

                      <td>{{user.id}}</td>
                      <td class="text-capitalize">{{user.type}}</td>
                      <td class="text-capitalize">{{user.name}}</td>
                      <td>{{user.email}}</td>
                      <td :inner-html.prop="user.email_verified_at | yesno"></td>
                      <td>{{user.created_at}}</td>

                      <td>

                        <a href="#" @click="editModal(user)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteUser(user.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="users" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New User</h5>
                    <h5 class="modal-title" v-show="editmode">Update User's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateUser() : createUser()">
                    <div class="modal-body">
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.name"
                                label="Όνοματεπώνυμο"
                                :class="{ 'is-invalid': form.errors.has('name') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.email"
                                label="Email"
                                :class="{ 'is-invalid': form.errors.has('email') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="email"></has-error>
                        </div>
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.password"
                                label="Κωδικός πρόσβασης"
                                :class="{ 'is-invalid': form.errors.has('password') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="password"></has-error>
                        </div>
                        <div class="form-group">
                            <v-select
                                dense
                                v-model="form.type"
                                label="Ρόλος"
                                :class="{ 'is-invalid': form.errors.has('type') }"
                                :items="roles"
                                item-text="name"
                                item-value="id"
                                @change="setRolePermissions()"
                            >
                            </v-select>
                            <has-error :form="form" field="type"></has-error>
                        </div>
                        <div class="form-group">
                            <v-select
                                dense
                                :chips="true"
                                :multiple="true"
                                v-model="form.permissions"
                                label="Προεπιλεγμένα δικαιώματα"
                                :items="permissions"
                                item-text="name"
                                item-value="id"
                            >
                            </v-select>
                        </div>
                        <div class="form-group">
                            <v-select
                                dense
                                :chips="true"
                                :multiple="true"
                                v-model="form.allowed_series_issuing"
                                label="Επιτρεπόμενες σειρές - έκδοση"
                                :items="invoiceSeries"
                                item-text="name"
                                item-value="id"
                            >
                            </v-select>
                        </div>
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.income_series"
                                label="Σειρά Εισπράξεων"
                                :class="{ 'is-invalid': form.errors.has('income_series') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="income_series"></has-error>
                        </div>
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.cancellation_series"
                                label="Σειρά Ακύρωσης"
                                :class="{ 'is-invalid': form.errors.has('cancellation_series') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="cancellation_series"></has-error>
                        </div>

                    
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                users : {},
                form: new Form({
                    id : '',
                    type : '',
                    name: '',
                    email: '',
                    password: '',
                    email_verified_at: '',
                    permissions: '',
                    allowed_series_issuing: '',
                    income_series: '',
                    cancellation_series: '',
                }),
                invoiceSeries: [],
                permissions : [],
                roles : [],
            }
        },
        methods: {
            loadInvoiceSeries(){
                axios.get("/api/invoiceSeries/getList").then(({ data }) => (this.invoiceSeries = data.data));
            },
            loadPermissions(){
                axios.get("/api/roles/permissions").then(({ data }) => (this.permissions = data.data));
            },
            loadRoles(){
                axios.get("/api/roles/getList").then(({ data }) => (this.roles = data.data));
            },
            getResults(page = 1) {
                this.$Progress.start();
                axios.get('api/user?page=' + page).then(({ data }) => (this.users = data.data));
                this.$Progress.finish();
            },
            updateUser(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('api/user/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadUsers();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            setRolePermissions(){
                for (let i = 0; i < this.roles.length; i++)
                {
                    if(this.roles[i].id == this.form.type)
                    {
                        this.form.permissions = JSON.parse(this.roles[i].permissions);
                    }
                }
            },


            editModal(user){
                this.editmode = true;
                if(user.permissions != null && user.permissions.length > 0)
                    user.permissions = JSON.parse(user.permissions);
                if(user.allowed_series_issuing != null && user.allowed_series_issuing.length > 0)
                    user.allowed_series_issuing = JSON.parse(user.allowed_series_issuing);
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(user);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            deleteUser(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('api/user/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadUsers();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },
          loadUsers(){
            this.$Progress.start();
            axios.get("api/user").then(({ data }) => (this.users = data.data));
            this.$Progress.finish();
          },
          
          createUser(){

              this.form.post('api/user')
              .then((response)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });

                  this.$Progress.finish();
                  this.loadUsers();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          }

        },
        mounted() {
            // console.log('User Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadRoles();
            this.loadPermissions();
            this.loadInvoiceSeries();
            this.loadUsers();
            this.$Progress.finish();
        }
    }
</script>
