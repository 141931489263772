<template>
  <section class="content">
    <div class="container-fluid">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Δημιουργία Παραστατικού</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία Παραστατικού</h5>
                </div>

                <div class="col-12"><strong>{{ (form.invoiceMark?'Το παραστατικό έχει συγχρονίσει με την ΑΑΔΕ με κωδικό MARK: ' + form.invoiceMark:'') }}</strong></div>

                <form @submit.prevent="editmode ? updateInvoice() : createInvoice()" data-app>

                    <div class="modal-body">

                      <div class="row">
                        <div class="form-group col-md-4">
                            <v-select tabindex="1"
                              :items="invoiceTypes"
                              autofocus="autofocus"
                              label="Τύπος παραστατικού"
                              item-text="name"
                              item-value="id"
                              dense
                              v-model="form.invoice_type_id"
                              :class="{ 'is-invalid': form.errors.has('invoice_type_id') }"
                              @change="invoiceTypeChanged"
                              :readonly="editmode || readonly"
                            ></v-select>
                          <has-error :form="form" field="invoice_type_id"></has-error>
                        </div>

                        <div class="form-group col-md-2">
                            <v-autocomplete 
                              :items="invoiceSeries"
                              label="Σειρά"
                              item-text="name"
                              item-value="id"
                              v-model="form.invoice_series_id"
                              :class="{ 'is-invalid': form.errors.has('invoice_series_id') }"
                              dense
                              :readonly="editmode || readonly"
                            ></v-autocomplete>
                          <has-error :form="form" field="invoice_series_id"></has-error>
                        </div>

                        <div class="form-group col-md-2">
                          <v-text-field
                            label="Αριθμός Παραστατικού"
                            readonly
                            v-model="form.invoice_number"
                            dense
                          ></v-text-field>
                          <has-error :form="form" field="invoice_number"></has-error>
                        </div>

                        <div class="form-group col-md-2">
                          <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. έκδοσης</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="form.issue_date"
                                    :readonly="editmode || readonly"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>

                        <div class="form-group col-md-2">
                          <v-text-field
                            label="Ώρα Έκδοσης"
                            v-model="form.issue_time"
                            :readonly="readonly"
                            dense
                          ></v-text-field>
                          <has-error :form="form" field="issue_time"></has-error>
                        </div>

                      </div>

                      <div v-if="form.invoice_type_id != 3 && form.invoice_type_id != 6">
                        <div class="row">
                          <div class="form-group col-md-4">
                              <v-autocomplete tabindex="2"
                                dense
                                label="Αποστολέας"
                                placeholder="Πληκτρολογήστε για αναζήτηση"
                                item-text="company_name"
                                item-value="id"
                                v-model="form.client_sender_id"
                                :class="{ 'is-invalid': form.errors.has('client_sender_id') }"
                                :items="senderClients"
                                :loading="loadingSenders"
                                :search-input.sync="searchSender"
                                @change="selectClientChargedId()"
                                @keypress="searchClients('sender')"
                                :readonly="readonly"
                              ></v-autocomplete>
                              <has-error :form="form" field="client_sender_id"></has-error>
                          </div>

                          <div class="form-group col-md-4">
                              <v-autocomplete tabindex="3"
                                label="Παραλήπτης"
                                item-text="company_name"
                                item-value="id"
                                v-model="form.client_receiver_id"
                                :class="{ 'is-invalid': form.errors.has('client_receiver_id') }"
                                @change="selectClientChargedId()"
                                dense
                                placeholder="Πληκτρολογήστε για αναζήτηση"
                                :items="receiverClients"
                                :loading="loadingReceivers"
                                :search-input.sync="searchReceiver"
                                @keypress="searchClients('receiver')"
                                :readonly="readonly"
                              ></v-autocomplete>
                              <has-error :form="form" field="client_receiver_id"></has-error>
                          </div>

                          <div class="form-group col-md-4">
                              <v-autocomplete tabindex="4"
                                label="Εντολέας"
                                item-text="company_name"
                                item-value="id"
                                v-model="form.client_issuer_id"
                                :class="{ 'is-invalid': form.errors.has('client_issuer_id') }"
                                @change="selectClientChargedId()"
                                dense
                                placeholder="Πληκτρολογήστε για αναζήτηση"
                                clearable
                                :items="issuerClients"
                                :loading="loadingIssuers"
                                :search-input.sync="searchIssuer"
                                @keypress="searchClients('issuer')"
                                :readonly="readonly"
                              ></v-autocomplete>
                              <has-error :form="form" field="client_issuer_id"></has-error>
                          </div>

                        </div>  

                         

                        <div class="row">
                          <div class="form-group col-md-3">
                              <v-combobox tabindex="5"
                                :items="areas"
                                label="Από Περιοχή"
                                v-model="form.from_area_id"
                                dense
                                :readonly="readonly"
                              ></v-combobox>
                              <has-error :form="form" field="from_area_id"></has-error>
                          </div>

                          <div class="form-group col-md-3">
                              <v-combobox tabindex="6"
                                :items="areas"
                                label="Σε Περιοχή"
                                v-model="form.to_area_id"
                                dense
                                :readonly="readonly"
                              ></v-combobox>
                              <has-error :form="form" field="to_area_id"></has-error>
                          </div>

                          <div class="form-group col-md-2">
                              <v-select tabindex="7"
                                :items="vehicles"
                                label="Όχημα"
                                item-text="plate"
                                item-value="id"
                                v-model="form.vehicle_id"
                                dense
                                :readonly="readonly"
                              ></v-select>
                              <has-error :form="form" field="vehicle_id"></has-error>
                          </div>

                          <div class="form-group col-md-2">
                            <v-autocomplete tabindex="8"
                              :items="charges"
                              label="Χρέωση"
                              item-text="name"
                              item-value="id"
                              v-model="form.client_charged"
                              :class="{ 'is-invalid': form.errors.has('client_charged') }"
                              @change="selectClientChargedId()"
                              dense
                              :readonly="readonly"
                            ></v-autocomplete>
                            <has-error :form="form" field="client_charged"></has-error>
                          </div>

                          <div class="form-group col-md-2">
                              <v-select tabindex="9"
                                :items="paymentTypes"
                                label="Τρόπος Πληρωμής"
                                item-text="name"
                                item-value="id"
                                v-model="form.payment_type_id"
                                dense
                                :readonly="readonly"
                              ></v-select>
                            <has-error :form="form" field="payment_type_id"></has-error>
                          </div>
                        </div>

                        <hr>
                        <div class="row">
                          <div class="form-group col-md-12">
                            <h5>Αντικείμενα τιμολογίου</h5>
                          </div>
                        </div>

                        <table>
                          <thead>
                            <tr>
                              <th class="p-1">Ποσότητα</th>
                              <th class="p-1">Μον. Μέτρησης</th>
                              <th class="p-1">Βάρος</th>
                              <th class="p-1">Τιμή Μον.</th>
                              <th class="p-1">Κόστος</th>
                              <th class="p-1">ΦΠΑ</th>
                              <th class="p-1">Τελική Αξία</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="i in invoiceItemsRowsCount" :key="`invoiceItem-${i}`">
                              <td class="form-group col-md-1">
                                <v-text-field 
                                  :tabindex="((i - 1) * 7) + 10"
                                  dense
                                  v-model="form.quantities[i]"
                                  @change="checkIfLastRow(i); calculateProductInitialCost(i);"
                                  :readonly="readonly"
                                ></v-text-field>
                                <has-error :form="form" field="third_party_fare"></has-error>
                              </td>
                              <td class="form-group col-md-3 p-1">
                                <v-combobox 
                                  :tabindex="((i - 1) * 7) + 11"
                                  dense
                                  v-model="form.quantity_types[i]"
                                  :items="quantityTypes"
                                  :readonly="readonly"
                                >
                                </v-combobox>
                                <!-- <v-text-field
                                  dense
                                  v-model="form.quantity_types[i]"
                                ></v-text-field> -->
                                <has-error :form="form" field="quantity_types"></has-error>
                              </td>
                              <td class="form-group col-md-1 p-1">
                                <v-text-field
                                  :tabindex="((i - 1) * 7) + 12"
                                  dense
                                  v-model="form.weights[i]"
                                  suffix="Κιλά"
                                  class="text-right"
                                  :readonly="readonly"
                                ></v-text-field>
                                <has-error :form="form" field="weights"></has-error>
                              </td>
                              <td class="form-group col-md-1 p-1">
                                <v-text-field
                                  :tabindex="((i - 1) * 7) + 13"
                                  dense
                                  v-model="form.unit_costs[i]"
                                  suffix="€"
                                  class="text-right"
                                  @change="checkIfLastRow(i); calculateProductInitialCost(i);"
                                  :readonly="readonly"
                                ></v-text-field>
                                <has-error :form="form" field="weights"></has-error>
                              </td>
                              <td class="form-group col-md-1 p-1">
                                <v-text-field
                                  :tabindex="((i - 1) * 7) + 14"
                                  dense
                                  suffix="€"
                                  class="text-right"
                                  v-model="form.initial_costs[i]"
                                  @change="calculateUnitCost(i); calculateSums();"
                                  :readonly="readonly"
                                ></v-text-field>
                                <has-error :form="form" field="initial_costs"></has-error>
                              </td>
                              <td class="form-group col-md-1 p-1">
                                <v-text-field
                                  :tabindex="((i - 1) * 7) + 15"
                                  dense
                                  suffix="€"
                                  class="text-right"
                                  v-model="form.vat_costs[i]"
                                  readonly
                                ></v-text-field>
                                <has-error :form="form" field="vat_costs"></has-error>
                              </td>
                              <td class="form-group col-md-1 p-1">
                                <div class="row">
                                  <div class="col-10 px-0">
                                    <v-text-field
                                      :tabindex="((i - 1) * 7) + 16"
                                      dense
                                      suffix="€"
                                      class="text-right"
                                      v-model="form.final_costs[i]"
                                      readonly
                                    ></v-text-field>
                                    <has-error :form="form" field="final_costs"></has-error>
                                  </div>
                                  <div class="col-2 px-1" v-if="form.quantities[i] > 0">
                                    <i class="fa fa-times cursor-pointer red py-3" @click="deleteRow(i)"></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br>
                        <br>

                        <hr>
                        <br>
                        <div class="row">

                          <div class="form-group col-md-1">
                            <v-text-field 
                              label="Ποσοστό ασφάλιστρων"
                              suffix="%"
                              v-model="form.insurance_cost_percentage"
                              @change="calculateSums()"
                              dense
                              :readonly="readonly"
                              class="text-right"
                            ></v-text-field>
                            <has-error :form="form" field="insurance_cost_percentage"></has-error>
                          </div>

                          <div class="form-group col-md-1">
                            <v-text-field
                              suffix="%"
                              label="Ποσ. ΦΠΑ"
                              v-model="form.vat_percentage"
                              @change="calculateSums()"
                              dense
                              :readonly="readonly"
                              class="text-right"
                            ></v-text-field>
                            <has-error :form="form" field="vat_percentage"></has-error>
                          </div>

                          <div class="form-group col-md-2">
                            <v-text-field
                              dense
                              readonly
                              label="Μερικό Κόστος"
                              suffix="€"
                              v-model="form.partial_cost"
                              class="text-right"
                            ></v-text-field>
                          </div>

                          <div class="form-group col-md-1">
                            <v-text-field
                              dense
                              readonly
                              suffix="€"
                              label="Ασφάλιστρο"
                              v-model="form.insurance_cost"
                              class="text-right"
                            ></v-text-field>
                          </div>

                          <div class="form-group col-md-2">
                            <v-text-field
                              dense
                              readonly
                              suffix="€"
                              label="Καθαρό Ποσό"
                              v-model="form.net_cost"
                              class="text-right"
                            ></v-text-field>  
                          </div> 

                          <div class="form-group col-md-1" >
                            <v-text-field
                              dense
                              readonly
                              suffix="€"
                              label="ΦΠΑ"
                              v-model="form.vat_cost"
                              class="text-right"
                            ></v-text-field>
                          </div>

                          <div class="form-group col-md-2">
                            <v-text-field
                              dense
                              readonly
                              suffix="€"
                              label="Σύνολο"
                              v-model="form.sum"
                              class="text-right"
                            ></v-text-field>
                          </div>

                            <div class="form-group col-md-2">
                              <v-text-field
                              dense
                              readonly
                              suffix="€"
                              label="Πληρωτέο"
                              v-model="form.sum_cost"
                              class="text-right"
                            ></v-text-field>
                            </div>   
                        </div>
                            
                        <div class="row"> 
                          <div class="form-group col-md-12">
                            <v-text-field
                              :tabindex="((invoiceItemsRowsCount - 1) * 7) + 17"
                              label="Αριθμοί Δελτίων"
                              v-model="form.ref_number"
                              dense
                              :readonly="readonly"
                              class="text-right"
                            ></v-text-field>
                            <has-error :form="form" field="ref_number"></has-error>
                          </div>
                        </div>

                        <hr>
                        <div class="row">
                              <div class="form-group col-md-12">
                                <h5>Επιπρόσθετα Στοιχεία</h5>
                              </div>  
                        </div>      
                                
                        <div class="row">
                          <div class="form-group col-md-4">
                            <v-text-field
                              :tabindex="((invoiceItemsRowsCount - 1) * 7) + 18"
                              label="Κόμιστρα τρίτων"
                              suffix="€"
                              v-model="form.third_party_fare"
                              @change="calculateSums()"
                              dense
                              :readonly="readonly"
                              class="text-right"

                            ></v-text-field>
                            <has-error :form="form" field="third_party_fare"></has-error>
                          </div>    

                          <div class="form-group col-md-4">
                            <v-text-field
                              :tabindex="((invoiceItemsRowsCount - 1) * 7) + 19"
                              label="Κόμιστρα τρίτων - Παραστατικό"
                              v-model="form.third_party_ref_number"
                              dense
                              :readonly="readonly"
                            ></v-text-field>
                            <has-error :form="form" field="third_party_ref_number"></has-error>
                          </div>

                          <div class="form-group col-md-4">
                            <v-text-field
                              :tabindex="((invoiceItemsRowsCount - 1) * 7) + 20"
                              label="Αντικαταβολή"
                              suffix="€"
                              v-model="form.pay_on_delivery_amount"
                              @change="calculateSums()"
                              dense
                              :readonly="readonly"
                              class="text-right"
                            ></v-text-field>
                            <has-error :form="form" field="pay_on_delivery_amount"></has-error>
                          </div>
                        </div>

                        <div class="row">
                          <div class="form-group col-md-6">
                              <v-textarea
                                  :tabindex="((invoiceItemsRowsCount - 1) * 7) + 21"
                                  dense
                                  label="Σημειώσεις"
                                  v-model="form.notes"
                                  no-resize
                              ></v-textarea>
                          </div>

                          <div class="form-group col-md-6">
                                <v-textarea
                                  :tabindex="((invoiceItemsRowsCount - 1) * 7) + 22"
                                  dense
                                  label="Ιδιωτικές Σημειώσεις"
                                  v-model="form.comments"
                                  no-resize
                                ></v-textarea>
                          </div>
                        </div>

                          <div class="row">
                            <div class="form-group col-md-4">
                              <v-select
                                :items="shippingStatus"
                                label="Κατάσταση"
                                item-text="name"
                                item-value="id"
                                dense
                                v-model="form.shipping_status_id"
                                :class="{ 'is-invalid': form.errors.has('shipping_status_id') }"
                              ></v-select>
                              <has-error :form="form" field="shipping_status_id"></has-error>
                            </div>

                            <div class="form-group col-md-4" v-if="!editmode && form.invoice_type_id != 5">
                                <v-checkbox
                                    v-model="form.paid"
                                    label="Πληρωμένο"
                                    :true-value="1" 
                                    :false-value="0"
                                >
                                </v-checkbox>
                            </div>
                          </div>

                        
                      </div>
                    </div>

                      <div v-if="form.invoice_type_id == 3">
                        <div class="row">

                          <div class="form-group col-md-3">
                            <v-text-field
                              label="Ποσό Έκτπωσης"
                              suffix="€"
                              v-model="form.sum"
                              @change="calculateCreditSum()"
                              dense
                            ></v-text-field>
                            <has-error :form="form" field="net_cost"></has-error>
                          </div>

                          <div class="form-group col-md-3">
                            <v-text-field
                              suffix="%"
                              label="Ποσοστό ΦΠΑ"
                              v-model="form.vat_percentage"
                              @change="calculateCreditSum()"
                              dense
                            ></v-text-field>
                            <has-error :form="form" field="vat_percentage"></has-error>
                          </div>

                          <div class="form-group col-md-3">
                            <v-text-field
                              label="Σύνολο"
                              suffix="€"
                              v-model="form.sum_cost"
                              @change="calculateCreditSum()"
                              dense
                            ></v-text-field>
                            <has-error :form="form" field="sum"></has-error>
                          </div>



                          <div class="form-group col-md-3">
                              <v-autocomplete
                                label="Πελάτης"
                                item-text="company_name"
                                item-value="id"
                                v-model="form.client_charged_id"
                                :class="{ 'is-invalid': form.errors.has('client_charged_id') }"
                                @change="selectClientChargedId()"
                                dense
                                placeholder="Πληκτρολογήστε για αναζήτηση"
                                :items="senderClients"
                                :loading="loadingSenders"
                                :search-input.sync="searchSender"
                                @keypress="searchClients('sender')"
                              ></v-autocomplete>
                              <has-error :form="form" field="client_charged_id"></has-error>
                          </div>
                          
                          <div class="col-md-6">
                            <v-textarea class="col-12"
                              dense
                              label="Σημειώσεις"
                              v-model="form.notes"
                              no-resize
                            ></v-textarea>
                          </div>
                        </div>
                      </div>


                      <div v-if="form.invoice_type_id == 6">
                        <div class="row">
                          <div class="form-group col-md-3">
                            <v-autocomplete
                              label="Πελάτης"
                              item-text="company_name"
                              item-value="id"
                              v-model="form.client_charged_id"
                              :class="{ 'is-invalid': form.errors.has('client_charged_id') }"
                              @change="selectClientChargedId();searchForStoixeiaDiakinisis()"
                              dense
                              placeholder="Πληκτρολογήστε για αναζήτηση"
                              :items="senderClients"
                              :loading="loadingSenders"
                              :search-input.sync="searchSender"
                              @keypress="searchClients('sender')"
                            ></v-autocomplete>
                            <has-error :form="form" field="client_charged_id"></has-error>
                          </div>
                        </div>

                        <div class="card-body table-responsive p-0">
                          <table class="table table-hover">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Αρ. Απόδειξης</th>
                                <th>Ημ. Πληρωμής</th>
                                <th>Ποσό</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="invoice in stoixeiaDiakinisis" :key="invoice.id">
                                <td>
                                  <v-checkbox
                                      v-model="form.stoixeiaDiakinisisSelected"
                                      :value="invoice.id"
                                      @change="calculateStoixeiaDiakinisisSum()"
                                  >
                                  </v-checkbox>
                                </td>
                                <td>{{invoice.invoice_full_number}}</td>
                                <td>{{$moment(invoice.issue_date).format('DD/MM/yyyy')}}</td>
                                <td>{{(invoice.sum !==''?invoice.sum:0)}}€</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>

                    <div class="modal-footer">
                        <button v-show="editmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Έκδοση</button>
                        <button v-show="editmode" type="button" class="btn btn-primary" @click="downloadPDF()">Λήψη Παραστατικού</button>
                        <button v-show="editmode" type="button" class="btn btn-info" @click="previewPDF()">Προεπισκόπιση Παραστατικού</button>
                        <button v-show="editmode && $gate.user.permissionsArray.includes('invoices.edit')" type="button" class="btn btn-warning" @click="emailInvoice()">Αποστολή Παραστατικού</button>
                    </div>
                  </form>
    </div>

    <div v-if="editmode" class="col-12" id="pdfPreview" ref="embeded">
      <embed width='100%' height='100%' :src="pdfOutput"/>
    </div>

  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'

    export default {
      components: {
          Datepicker
        },
        data () {
            return {
                editmode: false,
                readonly: false,
                ready: false,
                onlyCompaniesLoaded: false,
                invoices : {},
                form: new Form({
                    id : '',
                    client_sender_id: '',
                    client_issuer_id: '',
                    client_receiver_id: '',
                    client_charged_id: '',
                    client_charged: '',
                    from_area_id: '',
                    to_area_id: '',
                    vehicle_id: '',
                    ref_number: '',
                    insurance_cost_percentage: 0,
                    insurance_cost: '',
                    vat_percentage: 24,
                    sum_cost: '',
                    pay_on_delivery_amount: '',
                    third_party_fare: 0,
                    third_party_ref_number: '',
                    payment_type_id: 2,
                    invoice_type_id: '',
                    invoice_series_id: '',
                    invoice_prefix: '',
                    invoice_number: '',
                    invoice_full_number: '',
                    notes: '',
                    comments: '',
                    issue_date: new Date().toJSON().slice(0,10),
                    issue_time: new Date().toJSON().slice(11,16),
                    quantities: [[]],
                    quantity_types: [[]],
                    weights: [[]],
                    unit_costs: [[]],
                    initial_costs: [[]],
                    vat_costs: [[]],
                    final_costs: [[]],
                    partial_cost: '',
                    net_cost: '',
                    vat_cost: '',
                    insurance_cost: '',
                    sum: '',
                    paid: '',
                    stoixeiaDiakinisisSelected: [],
                    qrUrl: '',
                    invoiceMark: null,
                    should_sync: 0,
                    shipping_status_id: null,
                }),
                invoiceTypes: [],
                invoiceTypesWithKeys: {},
                quantityTypes: [],
                invoiceSeries: [],
                shippingStatus: [],
                areas: [],
                areasWithKeys: [],
                vehicles: [],
                vehiclesWithKeys: {},
                paymentTypes: [],
                paymentTypesWithKeys: {},
                charges: [{'id': 1, 'name': 'Αποστολέα', 'key': 'client_sender_id'}, {'id': 2, 'name': 'Παραλήπτη', 'key': 'client_receiver_id'}, {'id': 3, 'name': 'Εντολέα', 'key': 'client_issuer_id'}],
                invoiceItemsRowsCount: 1,

                searchSender: '',
                searchReceiver: '',
                searchIssuer: '',
                senderClients: [],
                receiverClients: [],
                issuerClients: [],
                loadingSenders: false,
                loadingReceivers: false,
                loadingIssuers: false,

                clientsWithKeys: {},
                chargedClientsWithKeys: {},

                stoixeiaDiakinisis: [],

                pdfOutput: null
            }
        },
        methods: {
          async loadClients(){
            let in_array = [];
            if(this.form.client_sender_id > 0)
              in_array.push(this.form.client_sender_id);
            if(this.form.client_receiver_id > 0)
              in_array.push(this.form.client_receiver_id);
            if(this.form.client_issuer_id > 0)
              in_array.push(this.form.client_issuer_id);
            if(this.form.client_charged_id > 0)
              in_array.push(this.form.client_charged_id);

            await axios.get("/api/clients/getList?in=" + in_array.join(',')).then(({ data }) => {
              this.senderClients = data.data
              this.receiverClients = data.data
              this.issuerClients = data.data


              let clientsWithKeys = {};
              for (let i = 0; i < data.data.length; i++)
                clientsWithKeys[data.data[i].id] = data.data[i];
              this.clientsWithKeys = clientsWithKeys;
            });
          },
          searchClients(field){
            let searchVar = '',
                dataVar = '';
            if(field == 'sender')
            {
              searchVar = 'searchSender';
              dataVar = 'senderClients';
            }
            else if(field == 'receiver')
            {
              searchVar = 'searchReceiver';
              dataVar = 'receiverClients';
            }
            else if(field == 'issuer')
            {
              searchVar = 'searchIssuer';
              dataVar = 'issuerClients';
            }

            if(this[searchVar] != null && this[searchVar].length == 3)
            {
              this.loadingSenders = true;
              axios.get("/api/clients/getList?search="+this[searchVar]).then(({ data }) => (this[dataVar] = data.data));
              this.loadingSenders = false;
            }
          },
          loadInvoice (){
            if(this.editmode == false)
              return;

            axios.get("/api/invoices/getOne/" + this.form.id).then(({ data }) => (this.parseInvoiceData(data.data)));
          },
          async parseInvoiceData(data){

            this.form.fill(data);

            if(this.form.invoiceMark > 0)
            {
              this.editmode = true;
              this.readonly = true;
            }

            //count invoice items for v-for to render the appropriate rows
            this.invoiceItemsRowsCount = this.form.quantities.length;

            //load after everything so that we have active invoice type
            await this.loadAreas();
            await this.loadInvoiceSeries();
            await this.loadClients();

            //να φέρει τα στοιχεία διακίνησης που έχουν επιλέγει και άλλα που δεν έχουν επιλεγεί από κάπου ακόμα.
            if(this.form.invoice_type_id == 6)
              this.searchForStoixeiaDiakinisis(this.form.id);

            //caclulate invoice items row for every row
            if(this.form.invoice_type_id != 3)
            {
              for (let i = 1; i < this.invoiceItemsRowsCount; i++)
                this.calculateProductInitialCost(i);
  
              this.calculateSums();
            }
          },
          loadInvoiceTypes(){
            axios.get("/api/invoiceTypes/getList").then(({ data }) => (this.parseInvoiceTypes(data.data)));
          },
          loadQuantityTypes(){
            axios.get("/api/quantityTypes/getListKeyValue").then(({ data }) => (this.quantityTypes = data.data));
          },
          parseInvoiceTypes(data){
            //αφαίρεση ειδικού ακυρωτικού στοιχείου αν δεν είμαστε σε edit
            if(!this.editmode)
            {
              for (let i = 0; i < data.length; i++)
              {
                if(data[i].id == 4)
                  data.splice(i, 1);              
              }
            }

            let invoiceTypesWithKeys = {};
            for (let i = 0; i < data.length; i++)
              invoiceTypesWithKeys[data[i].id] = data[i].name;


            this.invoiceTypes = data;
            this.invoiceTypesWithKeys = invoiceTypesWithKeys;
          },
          async loadAreas(){
            await axios.get("/api/areas/getList").then(({ data }) => (this.parseAreasData(data.data)));
          },
          parseAreasData(data){
            let areasArray = [];
            for (const id in data)
                areasArray.push(data[id]);

            this.areasWithKeys = data;
            this.areas = areasArray;

            //render areas correctly if in edit mode
            if(this.editmode)
            {
              this.form.to_area_id = this.areasWithKeys[this.form.to_area_id];
              this.form.from_area_id = this.areasWithKeys[this.form.from_area_id];
            }
          },
          loadVehicles(){
            axios.get("/api/vehicles/getList").then(({ data }) => (this.parseVehicles(data.data)));
          },
          parseVehicles(data){
            let vehiclesWithKeys = {};
            for (let i = 0; i < data.length; i++){
                vehiclesWithKeys[data[i].id] = data[i].plate;
            }
            this.vehicles = data;             
            this.vehiclesWithKeys = vehiclesWithKeys;
          },
          parseChargedClients(charges){
            let chargedClientsWithKeys = {};
            for (let i = 0; i < charges.length; i++) {
                chargedClientsWithKeys[charges[i].id] = charges[i].name;
            }
            this.chargedClientsWithKeys = chargedClientsWithKeys;
          },
//          loadPaymentTypes(){
//            axios.get("/api/paymentTypes/getList").then(({ data }) => (this.paymentTypes = data.data));
         loadPaymentTypes(){
            axios.get("/api/paymentTypes/getList").then(({ data }) => (this.parsePaymentTypes(data.data)));
          },
          parsePaymentTypes(data){
            let paymentTypesWithKeys = {};
            for (let i = 0; i < data.length; i++)
            {
              paymentTypesWithKeys[data[i].id] = data[i].name;  
            }
            this.paymentTypes = data;
            this.paymentTypesWithKeys = paymentTypesWithKeys;
          },
          async loadInvoiceSeries(){
            if(this.form.invoice_type_id == "")
              return;

            if(!this.editmode)
              this.form.invoice_number = '';

            await axios.get("/api/invoiceSeries/getList/" + this.form.invoice_type_id).then(({ data }) => (this.parseInvoiceSeries(data.data)));
          },
          parseInvoiceSeries(data){

            //filter invoice series
            if(this.$gate.user.allowed_series_issuing != null && this.$gate.user.allowed_series_issuing.length > 0)
            {
              let allowed = JSON.parse(this.$gate.user.allowed_series_issuing);
              for (let i = 0; i < data.length; i++)
              {
                if(!allowed.includes(parseInt(data[i].id)))
                  data.splice(i, 1);
              }
            }

            //add empty series if enabled in settings
            if(Vue.prototype.$settings['empty_series'] == 1)
            {
              let d = { id: 0, name: "Χωρίς Σειρά" }
              data.unshift(d);
            }

            //if we have only one series select it automatically
            if(data.length == 1 && !this.editmode)
              this.form.invoice_series_id = data[0].id;
            
            //set data
            this.invoiceSeries = data;
          },
          invoiceTypeChanged(){
            //φόρτωση σειρών για τον τύπο τιμολογίου που επιλέχθηκε
            this.loadInvoiceSeries();
          },
          checkIfLastRow(row) {
            if(row == this.invoiceItemsRowsCount)
              this.invoiceItemsRowsCount++;
          },
          deleteRow(row){
            this.form.quantities.splice(row, 1);
            this.form.quantity_types.splice(row, 1);
            this.form.weights.splice(row, 1);
            this.form.unit_costs.splice(row, 1);
            this.form.initial_costs.splice(row, 1);
            this.form.vat_costs.splice(row, 1);
            this.form.final_costs.splice(row, 1);
            this.invoiceItemsRowsCount--;
          },
          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },
          selectClientChargedId(){
            if(this.form.client_charged != '')
            {
              for (let i = 0; i < this.charges.length; i++)
              {
                if(this.charges[i].id == this.form.client_charged)
                {
                  let key = this.charges[i].key;
                  if(this.form[key] != '' && this.form[key] > 0)
                    this.form.client_charged_id = this.form[key];
                  else
                  {
                    this.form.client_charged = '';
                    this.form.client_charged_id = '';
                  }
                  break;
                }
              }
            }
          },
          searchForStoixeiaDiakinisis(invoice_id = ''){
            axios.get("/api/invoices/searchForStoixeiaDiakinisis/" + this.form.client_charged_id + '?invoice_id=' + invoice_id).then(({ data }) => (this.stoixeiaDiakinisis = data.data));
          },


          //financial entries
          calculateCents(amount){
            if(amount == null)
              return 0;

            amount = parseFloat(amount.toString().replace(',', '.'));
            amount = Math.round(amount * 100);
            if(isNaN(amount))
              amount = 0;

            return amount;
          },
          calculateProductInitialCost(index, recalcInitialCost = true){
            if(!(this.form.quantities[index] > 0 && typeof this.form.unit_costs[index] != 'undefined' && this.calculateCents(this.form.unit_costs[index]) > 0))
              return;
            if(recalcInitialCost)
              Vue.set(this.form.initial_costs, index, Math.round(parseInt(this.form.quantities[index]) * this.calculateCents(this.form.unit_costs[index]))  / 100);
            Vue.set(this.form.vat_costs, index, Math.round((this.calculateCents(this.form.initial_costs[index]) * parseInt(this.form.vat_percentage))  / 100) / 100);
            Vue.set(this.form.final_costs, index, (this.calculateCents(this.form.initial_costs[index]) + this.calculateCents(this.form.vat_costs[index]))  / 100);
            this.calculateSums();
          },
          calculateUnitCost(index){
            this.form.unit_costs[index] = this.calculateCents((this.calculateCents(this.form.initial_costs[index]) / this.form.quantities[index]) / 100) / 100;
            this.calculateProductInitialCost(index, false);
            this.calculateSums();
          },
          calculateSums(){
            let partial_cost = 0;
            for (let i = 1; i < this.form.initial_costs.length; i++)
            {
              let cost = parseFloat(this.form.initial_costs[i].toString().replace(',', '.'));
              cost = Math.round(cost * 100)  / 100
              if(cost > 0)
                partial_cost += cost;
            }
            this.form.partial_cost = partial_cost;

            //για να δουλεύουμε μόνο με ακέραιους
            this.form.insurance_cost = parseInt(this.calculateCents(this.form.partial_cost) * parseInt(this.form.insurance_cost_percentage) / 100) / 100;
            this.form.net_cost = (this.calculateCents(this.form.partial_cost) + this.calculateCents(this.form.insurance_cost)) / 100;
            this.form.vat_cost = this.calculateCents((this.calculateCents(this.form.net_cost) * parseInt(this.form.vat_percentage)) / 10000) / 100;
            this.form.sum = (this.calculateCents(this.form.vat_cost) + this.calculateCents(this.form.net_cost)) / 100;
            this.form.sum_cost = (this.calculateCents(this.form.sum) + this.calculateCents(this.form.pay_on_delivery_amount) + this.calculateCents(this.form.third_party_fare)) / 100;
          },
          calculateCreditSum(){
            if(this.form.sum > 0 && this.form.vat_percentage > 0)
            {
              this.form.vat_cost = this.calculateCents((this.calculateCents(this.form.sum) * parseInt(this.form.vat_percentage)) / 10000) / 100;
              this.form.sum_cost = (this.calculateCents(this.form.vat_cost) + this.calculateCents(this.form.sum)) / 100;
            }
          },
          calculateStoixeiaDiakinisisSum(){

            let items = [];
            for (let i = 0; i < this.stoixeiaDiakinisis.length; i++)
            {
              if(this.form.stoixeiaDiakinisisSelected.includes(this.stoixeiaDiakinisis[i].id))
              {
                items.push(this.stoixeiaDiakinisis[i]);
              }
            }

            let sumVat = 0,
                sum = 0,
                comments = [];
            for (let i = 0; i < items.length; i++)
            {
              sumVat += this.calculateCents(items[i].vat_cost);
              sum += this.calculateCents(items[i].sum);
              comments.push(items[i].invoice_full_number);
            }

            this.form.vat_cost = Math.round(sumVat) / 100;
            this.form.sum = Math.round(sum) / 100;
            this.form.sum_cost = this.form.sum
            this.form.comments = 'Αφορά τα παραστατικά: ' + comments.join(' ');
          },


          //Fuction for creating pdf. Should be moved to app.js
          preparePDF(){
            let additionalData = {};
            additionalData['clientsWithKeys'] = this.clientsWithKeys;
            additionalData['paymentTypesWithKeys'] = this.paymentTypesWithKeys;
            additionalData['invoiceTypesWithKeys'] = this.invoiceTypesWithKeys;
            additionalData['vehiclesWithKeys'] = this.vehiclesWithKeys;
            return Vue.prototype.$Invoice.preparePDF(this.form, additionalData);
          },
          previewPDF(){
            let jsPDF = this.preparePDF();
            this.pdfOutput = jsPDF.output('datauristring');


          },
          downloadPDF(){
            let pdfName = 'Παραστατικό ' + this.form.invoice_full_number;
            let jsPDF = this.preparePDF();
            jsPDF.save(pdfName + '.pdf');
          },
          emailInvoice(){

            Swal.fire({
                title: 'Αποστολή Παραστατικού',
                text: "Είστε σίγουροι ότι θέλετε να αποστείλετε το παραστατικό στα email του πελάτη;",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Ναι',
                cancelButtonText: 'Όχι'
                }).then((result) => {

                    // Send request to the server
                      if (result.value) {

                            let pdfName = 'Παραστατικό ' + this.form.invoice_full_number;
                            let jsPDF = this.preparePDF();
                            var pdf = btoa(jsPDF.output());


                            axios.post("/api/invoices/sendInvoice", {
                                                                      pdf: pdf,
                                                                      filename: pdfName,
                                                                      client_charged_id: this.form.client_charged_id
                                                                    }).then(()=>{
                            // this.form.cancel('/api/invoices/'+id).then(()=>{
                                  Swal.fire(
                                    'Αποστολή Παραστατικού',
                                    'Το παραστατικό εστάλη.',
                                    'success'
                                  );
                                // Fire.$emit('AfterCreate');
                            }).catch((data)=> {
                                Swal.fire("Failed!", data.message, "warning");
                            });
                      }
                })
            // console.log(pdf);
          },




       
          createInvoice(){

              if(this.form.invoiceMark == null && confirm('Να γίνει συγχρονισμός με ΑΑΔΕ;'))
                this.form.should_sync = 1;

              this.$Progress.start();

              this.form.post('/api/invoices')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();


                  let id = data.data.data.id;
                  // this.form.id = data.data.data.id;
                  // this.editmode = true;

                  this.$router.push({path: '/invoices/edit/' + id + '#embeded'})
                  
                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateInvoice(){
              if(this.form.invoiceMark == null && confirm('Να γίνει συγχρονισμός με ΑΑΔΕ;'))
                this.form.should_sync = 1;

              this.$Progress.start();
              this.form.put('/api/invoices/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');


              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
        },
        mounted() {
            //check if we should go down
            let hash = window.location.hash;
            if(hash.length > 0)
            {
              hash = hash.substring(1);
              this.$refs['embeded'].scrollIntoView({top: 0, left: 0, behavior: 'smooth'});
            }
        },
        created() {
            this.$Progress.start();

            if(!this.$gate.user.permissionsArray.includes('invoices.edit'))
              this.readonly = true;

            this.shippingStatus = Vue.prototype.$shippingStatus;

            this.loadQuantityTypes();
            this.loadInvoiceTypes();
            this.loadVehicles();
            this.loadPaymentTypes();

            if(this.$route.params.id > 0)
            {
              this.form.id = parseInt(this.$route.params.id)
              this.editmode = true;
              this.loadInvoice();
            }
            else
            {
              this.loadAreas();
            }

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
