<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Εξόδων</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary text-light" @click="newModal()" v-if="$gate.user.permissionsArray.includes('expenses.edit')">
                      <i class="fa fa-plus-square"></i>
                      Προσθήκη Νέου Έξοδου
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Προμηθευτής</th>
                      <th>Ημ. Πληρωμής</th>
                      <th>Σχετ. Παραστατικό</th>
                      <th>Περιγραφή</th>
                      <th>Ποσό</th>
                      <th>Υπόλοιπο</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="expense in expenses.data" :key="expense.id">

                      <td>{{expense.id}}</td>
                      <td>{{suppliersWithKeys[expense.supplier_id]}}</td>
                      <td>{{$moment(expense.expense_date).format('DD/MM/yyyy')}}</td>
                      <td>{{expense.ref_number}}</td>
                      <td>{{expense.notes}}</td>
                      <td>{{expense.sum_cost}}</td>
                      <td>{{expense.balance}}</td>
                      <td>
                        <a href="#" @click="editModal(expense)">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία Εξόδου">
                              <i class="fa fa-edit"></i>
                            </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="expenses" @pagination-change-page="loadExpenses"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Δημιουργία Νέου Εξόδου</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία Εξόδου</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateExpense() : createExpense()">
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-md-12">
                                <v-autocomplete
                                    label="Προμηθευτής"
                                    item-text="company_name"
                                    item-value="id"
                                    v-model="form.supplier_id"
                                    dense
                                    placeholder="Πληκτρολογήστε για αναζήτηση"
                                    :items="suppliers"
                                ></v-autocomplete>
                                <has-error :form="form" field="supplier_id"></has-error>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-6">
                                <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                                    <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot">
                                            <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. Εξόδου</label>
                                            <datepicker :format="customDateFormatter" v-model="form.expense_date"></datepicker>
                                        </div>
                                    </div>
                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <has-error :form="form" field="expense_date"></has-error>
                            </div>

                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="Σχετικό Παραστατικό"
                                    v-model="form.ref_number"
                                    dense
                                ></v-text-field>
                                <has-error :form="form" field="ref_number"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-4">
                                <v-combobox
                                    v-model="form.expense_category_id"
                                    :items="expense_categories"
                                    label="Κατηγορία Εξόδου"
                                    dense
                                    clearable
                                    type="text"
                                ></v-combobox>
                                <has-error :form="form" field="expense_categories"></has-error>
                            </div>
                            <div class="form-group col-md-4">
                                <v-text-field
                                    label="Περιγραφή"
                                    v-model="form.notes"
                                    dense
                                ></v-text-field>
                                <has-error :form="form" field="net_cost"></has-error>
                            </div>
                            <div class="form-group col-md-4">
                                <v-select
                                    :items="vehicles"
                                    label="Όχημα"
                                    item-text="plate"
                                    item-value="id"
                                    v-model="form.vehicle_id"
                                    clearable
                                    dense
                                ></v-select>
                              <has-error :form="form" field="vehicle_id"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-4">
                                <v-text-field
                                    label="Καθαρό Ποσό"
                                    v-model="form.net_cost"
                                    suffix="€"
                                    dense
                                    @change="calculateSumCost()"
                                ></v-text-field>
                                <has-error :form="form" field="net_cost"></has-error>
                            </div>
                            <div class="form-group col-md-4">
                                <v-text-field
                                    label="Φ.Π.Α"
                                    v-model="form.vat_percentage"
                                    suffix="%"
                                    dense
                                    @change="calculateSumCost()"
                                ></v-text-field>
                                <has-error :form="form" field="vat_percentage"></has-error>
                            </div>
                            <div class="form-group col-md-4">
                                <v-text-field
                                    label="Τελικό Ποσό"
                                    v-model="form.sum_cost"
                                    suffix="€"
                                    dense
                                    @change="calculateInitialCost()"
                                ></v-text-field>
                                <has-error :form="form" field="sum_cost"></has-error>
                            </div>
                        </div>

                        <div class="row" v-if="!editmode">
                          <div class="form-group col-md-12">
                              <v-checkbox
                                  v-model="form.paid"
                                  label="Πληρωμένο"
                                  :true-value="1" 
                                  :false-value="0"
                              >
                              </v-checkbox>
                          </div>
                        </div>


                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Κλείσιμο</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Δημιουργία</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';


    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                editmode: false,
                expenses: {},
                suppliers: [],
                suppliersWithKeys: [],
                expense_categories: [],
                expense_categoriesWithKeys: {},
                form: new Form({
                    id : '',
                    supplier_id: '',
                    expense_date: new Date().toJSON().slice(0,10),
                    expense_category_id: '',
                    vehicle_id: '',
                    ref_number: '',

                    net_cost: '',
                    vat_percentage: '24',
                    vat_cost: '',
                    sum_cost: '',

                    description: '',
                    balance: '',
                    paid: '',

                }),
                suppliersFound: [],
                loadingSuppliers: false,
                vehicles: [],
            }
        },
        methods: {
          loadVehicles(){
            axios.get("/api/vehicles/getList").then(({ data }) => (this.vehicles = data.data));
          },
          loadSuppliers(filter = ''){
            axios.get("/api/suppliers/getList" + filter).then(({ data }) => (this.parseSuppliers(data.data)));
          },
          loadExpenseCategories(){
            axios.get("/api/expenses/getExpenseCategoryList").then(({ data }) => {
                // this.expense_categories = data.data

                data = data.data;
                let array = [];
                let keys = {};
                for (let i = 0; i < data.length; i++)
                {
                    keys[data[i].id] = data[i].name;
                    array.push(data[i].name);
                }
                this.expense_categories = array;
                this.expense_categoriesWithKeys = keys;
            });
          },
          parseSuppliers(data){
            let suppliers = {};
            for (const i in data)
              suppliers[data[i]['id']] = data[i]['company_name'];
            this.suppliersWithKeys = suppliers;
            this.suppliers = data;
          },
          loadExpenses(page = 1) {
              this.$Progress.start();
              axios.get('/api/expenses?page=' + page).then(({ data }) => {
                  this.expenses = data.data;
              });
              this.$Progress.finish();
          },
          loadUnpaidInvoices(){
              if(this.form.client_id == 0)
                return;
              axios.get('/api/clients/getUnpaidInvoices/' + this.form.client_id).then(({ data }) => (this.invoices = data.data));
          },
          getInvoiceData(){
              axios.get('/api/invoices/getInvoiceByNumber/' + this.form.invoice_full_number).then(({ data }) => (this.parseInvoiceData(data.data)));
          },
          parseInvoiceData(data){
              if(data.balance == 0)
              {
                  alert('Το τιμολόγιο αυτό είναι πληρωμένο.');
                  this.form.invoice_id = '';
                  this.form.client_id = '';
                  this.form.balance = '';
                  return;
              }

              this.form.invoice_id = data.id;
              this.form.client_id = data.client_charged_id;
              this.form.balance = data.balance;
          },


          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },
          calculateCents(amount){
            if(amount == null)
              return 0;

            amount = parseFloat(amount.toString().replace(',', '.'));
            amount = Math.round(amount * 100);
            if(isNaN(amount))
              amount = 0;

            return amount;
          },
          calculateSumCost(){
            this.form.vat_cost = this.calculateCents((this.calculateCents(this.form.net_cost) * parseInt(this.form.vat_percentage)) / 10000) / 100;
            this.form.sum_cost = (this.calculateCents(this.form.vat_cost) + this.calculateCents(this.form.net_cost)) / 100;
          },
          calculateInitialCost(){
            this.form.vat_cost = this.calculateCents((this.calculateCents(this.form.sum_cost) * parseInt(this.form.vat_percentage)) / 10000) / 100;
            this.form.net_cost = (this.calculateCents(this.form.sum_cost) - this.calculateCents(this.form.vat_cost)) / 100;
          },





          editModal(expense){
              this.editmode = true;
              expense.expense_category_id = this.expense_categoriesWithKeys[String(expense.expense_category_id)];

              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(expense);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createExpense(){
              this.$Progress.start();

              //send combobox value if no doy is selected
              if(this.form.company_doy_id == "" && this.searchDoy.length > 0)
                  this.form.company_doy_id = this.searchDoy;
                // console.log(this.searchDoy);

              this.form.post('/api/expenses')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadExpenses();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateExpense(){
              this.$Progress.start();

              this.form.put('/api/expenses/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadExpenses();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadVehicles();
            this.loadSuppliers();
            this.loadExpenseCategories();
            this.loadExpenses();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
