<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Πληρωμών</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary text-light" @click="newModal()" v-if="$gate.user.permissionsArray.includes('expensePayments.edit')">
                      <i class="fa fa-plus-square"></i>
                      Προσθήκη Νέας Πληρωμής σε Προμηθευτή
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Προμηθευτής</th>
                      <th>Ημ. Πληρωμής</th>
                      <th>Τύπος Εξόδου</th>
                      <th>Περιγραφή</th>
                      <th>Ποσό</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="payment in payments.data" :key="payment.id">

                      <td>{{payment.id}}</td>
                      <td>{{(typeof suppliersWithKeys[payment.supplier_id] != 'undefined'?suppliersWithKeys[payment.supplier_id]:payment.supplier_id)}}</td>
                      <td>{{$moment(payment.payment_date).format('DD/MM/yyyy')}}</td>
                      <td>{{payment.expense_category}}</td>
                      <td>{{payment.notes}}</td>
                      <td>{{payment.amount}}</td>
                      <td>
                        <a href="#" @click="editModal(payment)">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία πληρωμής">
                                <i class="fa fa-edit"></i>
                            </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="payments" @pagination-change-page="loadPayments"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Δημιουργία Νέας Πληρωμής</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία Πληρωμής</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updatePayment() : createPayment()">
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-md-12" v-if="!editmode">
                                <v-autocomplete
                                    label="Προμηθευτής"
                                    item-text="company_name"
                                    item-value="id"
                                    v-model="form.supplier_id"
                                    dense
                                    placeholder="Πληκτρολογήστε για αναζήτηση"
                                    :items="suppliers"
                                    @change="loadUnpaidInvoices()"
                                ></v-autocomplete>
                                <has-error :form="form" field="supplier_id"></has-error>
                            </div>
                        </div>

                        <div class="row mb-4" v-if="!editmode">
                            <table class=" col-12 table table-hover">
                                <thead>
                                    <th></th>
                                    <th>Περιγραφή</th>
                                    <th>Ημ. Έκδοσης</th>
                                    <th>Ποσό</th>
                                    <th>Υπόλοιπο</th>
                                </thead>
                                <tbody>
                                    <tr v-for="invoice in invoices" :key="invoice.id">
                                        <td>
                                            <v-checkbox
                                                v-model="form.unpaidInvoicesSelected"
                                                :value="invoice.id"
                                            >
                                            </v-checkbox>
                                        </td>
                                        <td>{{invoice.notes}}</td>
                                        <td>{{$moment(invoice.expense_date).format('DD/MM/yyyy')}}</td>
                                        <td>{{invoice.sum_cost}}</td>
                                        <td>{{ (invoice.balance == ''?0:invoice.balance) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row" v-if="editmode">
                            <div class="col-12">
                                <strong>Έξοδο: </strong><span>{{form.expense_category + ' - ' + form.notes}}</span><br>
                                <strong>Προμηθευτής: </strong><span>{{(typeof suppliersWithKeys[form.supplier_id] != 'undefined')?suppliersWithKeys[form.supplier_id]:''}}</span><br>
                                <strong>Υπ. Εξόδου: </strong><span>{{form.balance}}</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-12">
                                <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                                    <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot">
                                            <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. Πληρωμής</label>
                                            <datepicker :format="customDateFormatter" v-model="form.payment_date"></datepicker>
                                        </div>
                                    </div>
                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <has-error :form="form" field="payment_date"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <v-text-field
                                    label="Περιγραφή"
                                    v-model="form.description"
                                    dense
                                ></v-text-field>
                                <has-error :form="form" field="description"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <v-text-field
                                    label="Ποσό"
                                    v-model="form.amount"
                                    
                                    dense
                                ></v-text-field>
                                <has-error :form="form" field="amount"></has-error>
                            </div>
                        </div>





                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Κλείσιμο</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Δημιουργία</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';


    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                editmode: false,
                form_mode: '',
                payments: {},
                invoiceTypes: [],
                suppliers: {},
                suppliersWithKeys: [],
                invoices: [],
                form: new Form({
                    id : '',
                    type_id: 1,
                    supplier_id: '',
                    invoice_id: '',
                    description: '',
                    payment_date: new Date().toJSON().slice(0,10),
                    amount: '',
                    unpaidInvoicesSelected: [],
                    invoice_full_number: '',
                    balance: '',
                    expense_category: '',
                    notes: '',
                }),
            }
        },
        methods: {

          loadSuppliers(){
            axios.get("/api/suppliers/getList").then(({ data }) => (this.parseSuppliers(data.data)));
          },
          parseSuppliers(data){
            let suppliers = {};
            for (const i in data)
              suppliers[data[i]['id']] = data[i]['company_name'];
            this.suppliers = data;
            this.suppliersWithKeys = suppliers;
          },
          loadPayments(page = 1) {
              this.$Progress.start();
              axios.get('/api/expense_payments?page=' + page).then(({ data }) => {
                  this.payments = data.data;
              });
              this.$Progress.finish();
          },
          loadUnpaidInvoices(){
              if(this.form.supplier_id == 0)
                return;
              axios.get('/api/suppliers/getUnpaidExpenses/' + this.form.supplier_id).then(({ data }) => (this.invoices = data.data));
          },
          getInvoiceData(){
              axios.get('/api/invoices/getInvoiceByNumber/' + this.form.invoice_full_number).then(({ data }) => (this.parseInvoiceData(data.data)));
          },
          parseInvoiceData(data){
              if(data.balance == 0)
              {
                  alert('Το τιμολόγιο αυτό είναι πληρωμένο.');
                  this.form.invoice_id = '';
                  this.form.supplier_id = '';
                  this.form.balance = '';
                  return;
              }

              this.form.invoice_id = data.id;
              this.form.supplier_id = data.client_charged_id;
              this.form.balance = data.balance;
          },

          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },

          editModal(payment){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(payment);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createPayment(){
              this.$Progress.start();

              //send combobox value if no doy is selected
              if(this.form.company_doy_id == "" && this.searchDoy.length > 0)
                  this.form.company_doy_id = this.searchDoy;
                // console.log(this.searchDoy);

              this.form.post('api/expense_payments')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadPayments();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updatePayment(){
              this.$Progress.start();

              //send combobox value if no doy is selected
              if(this.form.company_doy_id == "" && this.searchDoy.length > 0)
                  this.form.company_doy_id = this.searchDoy;

              this.form.put('api/expense_payments/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadPayments();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadPayments();
            this.loadSuppliers();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
