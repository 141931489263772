export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/invoices', component: require('./components/invoices/Index.vue').default },
    { 
        path: '/invoices/edit/:id?', 
        component: require('./components/invoices/Edit.vue').default,
        props: true,
        meta: { reuse: false }
    },
    { path: '/invoices/download', component: require('./components/invoices/Download.vue').default },

    { path: '/income', component: require('./components/income/Index.vue').default },

    { path: '/clients', component: require('./components/clients/Index.vue').default },
    { path: '/clients/sender/:id', component: require('./components/clients/List.vue').default, meta: { reuse: false }, name: 'sender' },
    { path: '/clients/receiver/:id', component: require('./components/clients/List.vue').default, meta: { reuse: false }, name: 'receiver' },
    { path: '/clients/issuer/:id', component: require('./components/clients/List.vue').default, meta: { reuse: false }, name: 'issuer' },
    { path: '/clients/charged/:id', component: require('./components/clients/List.vue').default, meta: { reuse: false }, name: 'charged' },
    { path: '/clients/charges/:id', component: require('./components/clients/List.vue').default, meta: { reuse: false }, name: 'charges' },

    
    { path: '/suppliers', component: require('./components/expenses/Suppliers.vue').default },
    { path: '/suppliers/charges/:id', component: require('./components/expenses/List.vue').default, meta: { reuse: false }, name: 'supplierCharges' },
    { path: '/expenses', component: require('./components/expenses/Expenses.vue').default },
    { path: '/payments', component: require('./components/expenses/Payments.vue').default },


    { path: '/settings/index', component: require('./components/settings/Index.vue').default },
    { path: '/settings/invoiceTypes', component: require('./components/invoices/InvoiceTypes.vue').default },
    { path: '/settings/invoiceSeries', component: require('./components/invoices/InvoiceSeries.vue').default },
    { path: '/settings/PaymentTypes', component: require('./components/paymentTypes/Index.vue').default },
    { path: '/settings/vehicles', component: require('./components/vehicles/Index.vue').default },
    { path: '/settings/quantityTypes', component: require('./components/invoices/QuantityTypes.vue').default },
    { path: '/settings/roles', component: require('./components/settings/Roles.vue').default },

    { path: '/reports', component: require('./components/reports/VehicleManifest.vue').default },

    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    
    { path: '*', component: require('./components/NotFound.vue').default }
];
