<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Τύπων Ποσοτήτων</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Προσθήκη Τύπου
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Τύπος</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="quantityType in quantityTypes.data" :key="quantityType.id">

                      <td>{{quantityType.id}}</td>
                      <td>{{quantityType.name}}</td>
                      <td>
                        
                        <a href="#" @click="editModal(quantityType)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="quantityTypes" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Δημιουργία Τύπου Ποσότητας</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία Τύπου Ποσότητας</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateQuantityTypes() : createQuantityTypes()" data-app>
                    <div class="modal-body">
                        <div class="row">
                          <div class="form-group col-md-12">
                            <v-text-field
                              label="Τύπος"
                              v-model="form.name"
                              required="true"
                            ></v-text-field>
                            <has-error :form="form" field="name"></has-error>
                          </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {
                editmode: false,
                form: new Form({
                    id : '',
                    name: '',
                }),
                quantityTypes: {},
                invoiceTypes: [],
                invoiceTypesWithKeys: {},
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {

          getResults(page = 1) {

              this.$Progress.start();
              axios.get('/api/quantityTypes?page=' + page).then(({ data }) => (this.quantityTypes = data.data));
              this.$Progress.finish();
          },
          loadquantityTypes(){

            // if(this.$gate.isAdmin()){
              axios.get("/api/quantityTypes").then(({ data }) => (this.quantityTypes = data.data));
            // }
          },


          editModal(quantityTypes){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(quantityTypes);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createQuantityTypes(){
              this.$Progress.start();

              this.form.post('/api/quantityTypes')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadquantityTypes();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateQuantityTypes(){
              this.$Progress.start();
              this.form.put('/api/quantityTypes/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadquantityTypes();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          // deleteProduct(id){
          //     Swal.fire({
          //         title: 'Are you sure?',
          //         text: "You won't be able to revert this!",
          //         showCancelButton: true,
          //         confirmButtonColor: '#d33',
          //         cancelButtonColor: '#3085d6',
          //         confirmButtonText: 'Yes, delete it!'
          //         }).then((result) => {

          //             // Send request to the server
          //               if (result.value) {
          //                     this.form.delete('api/quantityTypes/'+id).then(()=>{
          //                             Swal.fire(
          //                             'Deleted!',
          //                             'Your file has been deleted.',
          //                             'success'
          //                             );
          //                         // Fire.$emit('AfterCreate');
          //                         this.loadquantityTypes();
          //                     }).catch((data)=> {
          //                         Swal.fire("Failed!", data.message, "warning");
          //                     });
          //               }
          //         })
          // },

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadquantityTypes();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
