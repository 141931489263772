<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
                <datepicker class="pull-center bg-white p-3" :format="customDateFormatter" v-model="date_selected" @selected="loadDaysVehicleList"></datepicker>
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Διαθέσιμων Οχημάτων</h3>

                <div class="card-tools">
                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Όχημα</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="vehicle in availableVehicles.data" :key="vehicle.id">

                      <td>{{vehicle.id}}</td>
                      <td>{{vehicle.plate}}</td>
                      <td>
                        <a href="#" @click="downloadManifest(vehicle.id)">
                            <button class="bg-primary btn btn-sm">
                              <i class="fa fa-download"></i>
                            </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>

    </div>
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'

    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                editmode: false,
                form_mode: '',
                invoices: [],
                date_selected: new Date().toJSON().slice(0,10),
                invoiceTypes: {},
                vehicles: [],
                vehiclesWithKeys: {},
                clients: [],
                availableVehicles: {},

            }
        },
        methods: {
          loadInvoiceTypes(){
            axios.get("/api/invoiceTypes/getList").then(({ data }) => (this.parseInvoiceTypes(data.data)));
          },
          parseInvoiceTypes(data){
              let types = {};
              for (let i = 0; i < data.length; i++)
                  types[data[i].id] = data[i].name;

              this.invoiceTypes = types;
          },

          loadVehicles(){
            axios.get("/api/vehicles/getList").then(({ data }) => (this.parseVehicles(data.data)));
          },

          parseVehicles(data){
            let vehiclesWithKeys = {};
            for (let i = 0; i < data.length; i++) {
              vehiclesWithKeys[data[i].id] = data[i].plate;    
            }
            this.vehicles = data;
            this.vehiclesWithKeys = vehiclesWithKeys;
          },

          loadDaysVehicleList(date){
              axios.get('/api/vehicles/getAvailableVehicles/' + this.$moment(date).format('yyyy-MM-DD')).then(({ data }) => (this.availableVehicles = data));
          },

          async loadClients(filter = ''){
            await axios.get("/api/clients/getList" + filter).then(({ data }) => {
              // this.clients = data.data
              let clients = {};
              for (let i = 0; i < data.data.length; i++)
                clients[data.data[i].id] = data.data[i];
              this.clients = clients;

            });
          },

        //   loadCompanySettings(){
        //     axios.get("/api/invoices/getCompanyDetails").then(({ data }) => (this.clients = data.data));
        //   },

          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },

          downloadManifest(vehicle_id){
              axios.get('/api/vehicles/getVehicleInvoices/' + this.$moment(this.date_selected).format('yyyy-MM-DD') + '/' + vehicle_id).then(({ data }) => (this.parseManifestData(data.data)));
          },

          async parseManifestData(invoices){

            let client_ids = []
            for (let i = 0; i < invoices.length; i++)
            {
              client_ids.push(invoices[i].sender);
              client_ids.push(invoices[i].receiver);
            }
            let filter = '?in=' + client_ids.join(',');

            await this.loadClients(filter);

            let pdfName = 'Δηλωτικό'; 
            var doc = new jsPDF('l','px', 'a4');
            
            //Declare Fonts used
            doc.addFont("/fonts/Roboto/Roboto-Black.ttf", "Roboto", "black");
            doc.addFont("/fonts/Roboto/Roboto-Bold.ttf", "Roboto", "bold");
            doc.addFont("/fonts/Roboto/Roboto-Light.ttf", "Roboto", "light");
            doc.addFont("/fonts/Roboto/Roboto-Medium.ttf", "Roboto", "medium");
            doc.addFont("/fonts/Roboto/Roboto-Regular.ttf", "Roboto", "regular");
            doc.addFont("/fonts/Roboto/Roboto-Thin.ttf", "Roboto", "thin");

            //Declare colors used
            var black = "000000";
            var mainAccentColor = "E5322D";
            var mainTextColor = "323232";


            doc.addImage("/images/logo.png", "PNG", 30, 10, 50, 30);
            doc.setFont("Roboto", "regular");
            doc.setFontSize(8);
            //Needs proper vehicle_id argument
            doc.text ("Δηλωτικό Οχήματος  " + this.vehiclesWithKeys[1], 90 , 20); 
            doc.text("Ημερομηνία: " + this.$moment(this.date_selected).format('DD-MM-YYYY'), 90, 30);            

            /*
                let x = 80,
                y = 10,
                step = 5,
                counter = 0;
            doc.text(Vue.prototype.$settings.company_name, x, y + (step * counter++));
            doc.text(Vue.prototype.$settings.company_occupation, x, y + (step * counter++));
            doc.text(Vue.prototype.$settings.company_afm, x, y + (step * counter++));
            if(Vue.prototype.$settings.company_doy)
              doc.text(Vue.prototype.$settings.company_doy, x, y + (step * counter++));
            if(Vue.prototype.$settings.landline)
              doc.text(Vue.prototype.$settings.landline, x, y + (step * counter++));
            if(Vue.prototype.$settings.email)
              doc.text(Vue.prototype.$settings.email, x, y + (step * counter++));
            */

            var columns = [
                {title: "Α/Α", dataKey: "count"},
                {title: "Τύπος Παρ.", dataKey: "invoice_type"},
                {title: "Αρ. Παρ.", dataKey: "invoice_full_number"},
                {title: "Περιεχόμενο", dataKey: "contents"},
                {title: "Αποστολέας", dataKey: "sender"},
                {title: "Παραλήπτης", dataKey: "receiver"},
                {title: "Κόμιστρα", dataKey: "cost"},

            ];

            let data = [];
            for (let i = 0; i < invoices.length; i++)
            {
              let d = {
                  'count': invoices[i].count, 
                  'invoice_type': this.invoiceTypes[invoices[i].invoice_type_id],
                  'invoice_full_number': invoices[i].invoice_full_number, 
                  'contents': invoices[i].contents, 
                  'sender': (typeof this.clients[invoices[i].sender] != 'undefined'?this.clients[invoices[i].sender].company_name:''),
                  'receiver': (typeof this.clients[invoices[i].receiver] != 'undefined'?this.clients[invoices[i].receiver].company_name:''),
                  'cost': invoices[i].cost + '€'
                };
              data.push(d);
            }

            doc.autoTable({
              columns: columns,
              body: data,
              headStyles: {font: "Roboto", fontSize: 10, fillColor: mainAccentColor},
              bodyStyles: {font: "Roboto", fontSize: 9, fontStyle: "regular", textcolor: mainTextColor },
              startY: 45
            });

            doc.save(pdfName + '.pdf');
          }

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadInvoiceTypes();
            this.loadVehicles();
            this.loadDaysVehicleList();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
