<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Προμηθευτών</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary text-light" @click="newModal" v-if="$gate.user.permissionsArray.includes('suppliers.edit')">
                      <i class="fa fa-plus-square"></i>
                      Προσθήκη Νέου
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Τύπος</th>
                      <th>Όνομα / Επωνυμία</th>
                      <th>Διεύθυνση</th>
                      <th>Τηλέφωνο</th>
                      <th>ΑΦΜ</th>
                      <th>Κατάσταση</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="client in clients.data" :key="client.id">

                      <td>{{client.id}}</td>
                      <td>{{clientTypesList[client.type_id]}}</td>
                      <td>{{client.type_id == 1?client.surname + ' ' + client.name:client.company_name}}</td>
                      <td>{{client.address}}</td>
                      <td>{{(client.landline == null?'':client.landline + ' ')  + (client.cellphone == null?'':client.cellphone)}}</td>
                      <td>{{client.company_afm}}</td>
                      <td>
                          <v-btn v-show="client.active == 1"
                            elevation="2"
                            x-small
                            color="#1867C0"
                            >ΕΝΕΡΓΟΣ</v-btn>
                          <v-btn v-show="client.active == 0"
                            elevation="2"
                            x-small
                            color="#CB2939"
                            >ΑΝΕΝΕΡΓΟΣ</v-btn>
                      </td>
                      <td>
                        <a href="#" @click="editPreviousBalanceModal(client)">
                            <button class="bg-danger btn btn-sm" title="Επεξεργασία Προηγούμενου Υπόλοιπου Προμηθευτή">
                                <i class="fa fa-balance-scale white"></i>
                            </button>
                        </a>
                        <router-link :to="{ name: 'supplierCharges', params: { id: client.id } }">
                            <button class="bg-light btn btn-sm" title="Πληροφορίες Πελάτη">
                                <i class="fa fa-list black"></i>
                            </button>
                        </router-link>
                        <a href="#" @click="editModal(client)">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία Προμηθευτή">
                                <i class="fa fa-edit"></i>
                            </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="clients" @pagination-change-page="loadClients"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="previousBalanceForm" tabindex="-1" role="dialog" aria-labelledby="previousBalanceForm" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-show="!editmode">Επεξεργασία Προηγούμενου Υπόλοιπου</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="updatePreviousBalance()">
                            <div class="form-group col-md-12">
                                <v-text-field
                                    label="Προηγούμενο Υπόλοιπο"
                                    v-model="previous_balance"
                                ></v-text-field>
                            </div>
                            <button type="submit" class="btn btn-success">Ενημέρωση</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Δημιουργία Νέου Προμηθευτή</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία Προμηθευτή</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateClient() : createClient()">
                    <div class="modal-body">
                        <div class="form-group col-md-12">
                            <div class="row">
                                <v-select
                                    :items="clientTypes"
                                    label="Τύπος Προμηθευτή"
                                    item-text="name"
                                    item-value="id"
                                    v-model="form.type_id"
                                ></v-select>
                            </div>
                            <has-error :form="form" field="type_id"></has-error>
                        </div>

                        <div v-if="form.type_id == 1">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <v-text-field
                                        label="Επώνυμο"
                                        v-model="form.surname"
                                    ></v-text-field>
                                    <has-error :form="form" field="surname"></has-error>
                                </div>
                                <div class="form-group col-md-6">
                                    <v-text-field
                                        label="Όνομα"
                                        v-model="form.name"
                                    ></v-text-field>
                                    <has-error :form="form" field="name"></has-error>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <v-text-field
                                        label="Αρ. Ταυτότητας"
                                        v-model="form.id_number"
                                    ></v-text-field>
                                    <has-error :form="form" field="id_number"></has-error>
                                </div>
                            </div>
                        </div>

                        <div v-if="form.type_id == 2">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <v-text-field
                                        label="ΑΦΜ"
                                        v-model="form.company_afm"
                                        @keyup="getCompanyDetails"
                                        :class="{ 'is-invalid': form.errors.has('company_afm') }"
                                    ></v-text-field>
                                    <has-error :form="form" field="company_afm"></has-error>
                                </div>
                                <div class="form-group col-md-6">
                                    <v-text-field
                                        label="Επωνυμία"
                                        v-model="form.company_name"
                                    ></v-text-field>
                                    <has-error :form="form" field="company_name"></has-error>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <v-text-field
                                        label="Επάγγελμα"
                                        v-model="form.company_occupation"
                                    ></v-text-field>
                                    <has-error :form="form" field="company_occupation"></has-error>
                                </div>
                                <div class="form-group col-md-6">
                                    <v-combobox
                                        v-model="form.company_doy"
                                        :items="doys"
                                        label="ΔΟΥ"
                                        @keyup="toUpperCase('company_doy')"
                                        :search-input.sync="searchDoy"
                                        type="text"
                                    ></v-combobox>
                                    <has-error :form="form" field="company_doy"></has-error>
                                </div>
                            </div>
                        </div>
                        <hr>

                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="Διεύθυνση"
                                    v-model="form.address"
                                ></v-text-field>
                                <has-error :form="form" field="address"></has-error>
                            </div>
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="Σταθερό Τηλέφωνο"
                                    v-model="form.landline"
                                ></v-text-field>
                                <has-error :form="form" field="landline"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="Κινητό Τηλέφωνο"
                                    v-model="form.cellphone"
                                ></v-text-field>
                                <has-error :form="form" field="cellphone"></has-error>
                            </div>
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="ΦΑΞ"
                                    v-model="form.fax"
                                ></v-text-field>
                                <has-error :form="form" field="fax"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="Email 1"
                                    v-model="form.email1"
                                ></v-text-field>
                                <has-error :form="form" field="email1"></has-error>
                            </div>
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="Email 2"
                                    v-model="form.email2"
                                ></v-text-field>
                                <has-error :form="form" field="email2"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-text-field
                                    label="Email 3"
                                    v-model="form.email3"
                                ></v-text-field>
                                <has-error :form="form" field="email3"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <v-textarea
                                    label="Σχόλια"
                                    v-model="form.comments"
                                ></v-textarea>
                                <has-error :form="form" field="email3"></has-error>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-6">
                                <v-checkbox
                                    v-model="form.active"
                                    label="Ενεργός"
                                    :true-value="1" 
                                    :false-value="0"
                                >
                                </v-checkbox>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                clients : {},
                form: new Form({
                    id : '',
                    type_id: 1,
                    surname: '',
                    name: '',
                    id_number: '',
                    company_name: '',
                    company_occupation: '',
                    address: '',
                    company_afm: '',
                    company_doy: '',
                    company_doy_id: '',
                    landline: '',
                    cellphone: '',
                    fax: '',
                    email1: '',
                    email2: '',
                    email3: '',
                    active: 1,
                }),
                previousBalanceForm: new Form({
                    id: '',
                    previous_balance: 0
                }),
                previous_balance: 0,
                searchDoy: '',
                doys: [],
                doysWithKeys: [],
                clientTypes: [{id: 1, name: 'Ιδιώτης'}, {id: 2, name: 'Εταιρία'}],
                clientTypesList: ['', 'Ιδιώτης', 'Εταιρία'],
                apiPage: 'clients'
            }
        },
        watch: {
            previous_balance(val) {
                this.previous_balance = val.toString().replaceAll(',', '.').replaceAll('..', '.');
                this.previousBalanceForm.previous_balance = this.previous_balance;
            },
        },
        methods: {

          loadClients(page = 1) {
              this.$Progress.start();
              axios.get('/api/' + this.apiPage + '?page=' + page).then(({ data }) => (this.clients = data.data));
              this.$Progress.finish();
          },
          loadDoys(){
              //get without ids for combobox
              axios.get("/api/doys/list").then(({ data }) => (this.parseDoys(data.data)));
          },
          parseDoys(data){
              let doysArray = [];
              for (const id in data)
                  doysArray.push(data[id]);

              this.doysWithKeys = data;
              this.doys = doysArray;
          },
          getCompanyDetails(){
              if(this.form.company_afm.length == 9)
                axios.get("/api/invoices/getCompanyDetails/" + this.form.company_afm + '/suppliers').then(({ data }) => (this.setCompanyDetails(data.data)));
          },
          setCompanyDetails(data){
              var data = JSON.parse(data);
              this.form.company_name = data.fullName;
              this.searchDoy = data.doyDescr;
              this.form.address = data.addressFull;
              this.form.company_occupation = data.kad.base[Object.keys(data.kad.base)[0]];
          },


        editPreviousBalanceModal(supplier){
            this.previousBalanceForm.reset();
            this.previous_balance = supplier.previous_balance;

            $('#previousBalanceForm').modal('show');
            this.previousBalanceForm.fill(supplier);
        },
        updatePreviousBalance(){
            this.$Progress.start();

            this.previousBalanceForm.put('api/' + this.apiPage + '/updateBalance/'+this.previousBalanceForm.id)
            .then((response) => {
                // success
                $('#previousBalanceForm').modal('hide');
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                });
                this.loadClients();

                this.$Progress.finish();
            })
            .catch(() => {
                this.$Progress.fail();
            });
        },




          editModal(client){
              this.editmode = true;
              this.form.reset();
              client.company_doy = this.doysWithKeys[String(client.company_doy_id)];
            //   console.log(client.company_doy, client.company_doy_id, this.doysWithKeys, this.doysWithKeys[String(client.company_doy_id)]);
              $('#addNew').modal('show');
              this.form.fill(client);
              console.log(client);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createClient(){
              this.$Progress.start();

              //send combobox value
              if((typeof this.form.company_doy_id == 'undefined' || this.form.company_doy_id == "") && this.searchDoy.length > 0)
              {
                  this.form.company_doy_id = this.searchDoy;
                  this.form.company_doy = this.searchDoy;
              }

              this.form.post('api/' + this.apiPage)
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadClients();
                  this.loadDoys();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateClient(){
              this.$Progress.start();

              //send combobox value if no doy is selected
              if(this.form.company_doy_id == "" && this.searchDoy.length > 0)
                  this.form.company_doy_id = this.searchDoy;

              this.form.put('api/' + this.apiPage + '/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadClients();
                  this.loadDoys();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
        },
        mounted() {
            
        },
        created() {
            //select clients or suppliers
            this.apiPage = this.$route.path.replace('/', '');

            this.$Progress.start();

            this.loadClients();
            this.loadDoys();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
