<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Φόρμα Αναζήτησης</h3>
              </div>
              <div class="card-body">

                <form @submit.prevent="searchClients()">
                    <div class="row">

                        <div class="col-md-4">
                            <v-autocomplete
                                label="Πελάτης"
                                item-text="company_name"
                                item-value="id"
                                v-model="search_client_id"
                                dense
                                placeholder="Πληκτρολογήστε Ονομασία / ΑΦΜ / Τηλέφωνο για αναζήτηση"
                                :items="clientsFound"
                                :loading="loadingClients"
                                :search-input.sync="searchClient"
                                clearable
                                @keypress="searchClients('searchForm')"
                                @change="loadSearchedPayments()"
                            ></v-autocomplete>
                        </div>

                        <div class="col-md-4">
                            <v-text-field
                                label="Αρ. Παραστατικού"
                                v-model="search_invoice_full_number"
                                dense
                                clearable
                                placeholder="Πληκτρολογήστε τον αριθμό παραστατικού"
                                @change="loadSearchedPayments()"
                            ></v-text-field>
                        </div>

                        <div class="col-md-4">
                          <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. έκδοσης</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_payment_date" 
                                    @selected="loadSearchedPayments" 
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>

                </form>

              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Εισπράξεων</h3>

                <div class="card-tools" v-if="$gate.user.permissionsArray.includes('payments.edit')">
                  
                  <button type="button" class="btn btn-sm btn-primary text-light" @click="newModal('invoice')">
                      <i class="fa fa-plus-square"></i>
                      Προσθήκη Νέας Πληρωμής σε Παραστατικό
                  </button>
                  <button type="button" class="btn btn-sm btn-primary text-light" @click="newModal('client')">
                      <i class="fa fa-plus-square"></i>
                      Προσθήκη Νέας Πληρωμής σε Πελάτη
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Αρ. Απόδειξης</th>
                      <th>Ημ. Πληρωμής</th>
                      <th>Τύπος Παραστατικού</th>
                      <th>Αρ. Παραστατικού</th>
                      <th>Πελάτης</th>
                      <th>Περιγραφή</th>
                      <th>Ποσό</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="payment in payments.data" :key="payment.id">

                      <td>{{payment.id}}</td>
                      <td>{{(payment.series != null?payment.series:'') + payment.number.toString().padStart(4, '0')}}</td>
                      <td>{{$moment(payment.payment_date).format('DD/MM/yyyy')}}</td>
                      <td>{{invoiceTypes[payment.invoice_type_id]}}</td>
                      <td>{{payment.invoice_full_number}}</td>
                      <td>{{(typeof clientsWithKeys[payment.client_id] != 'undefined'?clientsWithKeys[payment.client_id]:payment.client_id)}}</td>
                      <td>{{payment.description}}</td>
                      <td>{{(payment.amount !==''?payment.amount:0)}}€</td>
                      <td>
                        <a href="#" @click="editModal(payment)">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία πληρωμής ">
                              <i class="fa fa-edit"></i>
                            </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="payments" @pagination-change-page="loadPayments"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Δημιουργία Νέας Είσπραξης</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία Είσπραξης</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updatePayment() : createPayment()">
                    <div class="modal-body">
                        <div class="row" v-if="form_mode=='client'">
                            <div class="form-group col-md-12">
                                <v-autocomplete
                                    label="Πελάτης"
                                    item-text="company_name"
                                    item-value="id"
                                    v-model="form.client_id"
                                    dense
                                    placeholder="Πληκτρολογήστε για αναζήτηση"
                                    :items="clientsFound"
                                    :loading="loadingClients"
                                    :search-input.sync="searchClientEdit"
                                    @keypress="searchClients('editForm')"
                                    @change="loadUnpaidInvoices()"
                                ></v-autocomplete>
                                <has-error :form="form" field="client_id"></has-error>
                            </div>
                        </div>

                        <div class="row mb-4" v-if="form_mode=='client'">
                            <table class=" col-12 table table-hover">
                                <thead>
                                    <th></th>
                                    <th>Αρ. Παραστατικού</th>
                                    <th>Ημ. Έκδοσης</th>
                                    <th>Ποσό</th>
                                    <th>Υπόλοιπο</th>
                                </thead>
                                <tbody>
                                    <tr v-for="invoice in invoices" :key="invoice.id">
                                        <td>
                                            <v-checkbox
                                                v-model="form.unpaidInvoicesSelected"
                                                :value="invoice.id"
                                            >
                                            </v-checkbox>
                                        </td>
                                        <td>{{invoice.invoice_full_number}}</td>
                                        <td>{{$moment(invoice.issue_date).format('DD/MM/yyyy')}}</td>
                                        <td>{{invoice.sum_cost}}</td>
                                        <td>{{ (invoice.balance == ''?0:invoice.balance) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row" v-if="editmode">
                            <div class="col-12">
                                <strong>Αρ. Παραστατικού: </strong><span>{{form.invoice_full_number}}</span><br>
                                <strong>Πελάτης: </strong><span>{{(typeof clientsWithKeys[form.client_id] != 'undefined')?clientsWithKeys[form.client_id]:''}}</span><br>
                                <strong>Υπ. Παραστατικού: </strong><span>{{form.balance}}</span>
                            </div>
                        </div>

                        <div class="row" v-if="form_mode == 'invoice'">
                            <div class="form-group col-10">
                                <v-text-field
                                    label="Αρ. Παραστατικού"
                                    v-model="form.invoice_full_number"
                                    dense
                                    placeholder="Πληκτρολογήστε τον αριθμό παραστατικού και πατήστε αναζήτηση."
                                ></v-text-field>
                                <has-error :form="form" field="invoice_full_number"></has-error>
                            </div>
                            <div class="col-2">
                                <button class="btn btn-light" type="button" @click="getInvoiceData()">
                                    <i class="fas fa-search black"></i>
                                </button>
                            </div>
                        </div>

                        <div class="row mb-4" v-if="form_mode=='invoice' && invoices.length > 0">
                            <table class=" col-12 table table-hover">
                                <thead>
                                    <th></th>
                                    <th>Είδος Παραστατικού</th>
                                    <th>Πελάτης</th>
                                    <th>Ημ. Έκδοσης</th>
                                    <th>Ποσό</th>
                                    <th>Υπόλοιπο</th>
                                </thead>
                                <tbody>
                                    <tr v-for="invoice in invoices" :key="invoice.id">
                                        <td>
                                            <v-radio
                                                @click="selectInvoice(invoice.id)"
                                            >
                                            </v-radio>
                                        </td>
                                        <td>{{invoiceTypes[invoice.invoice_type_id]}}</td>
                                        <td>{{invoice.client}}</td>
                                        <td>{{$moment(invoice.issue_date).format('DD/MM/yyyy')}}</td>
                                        <td>{{invoice.sum}}</td>
                                        <td>{{ (invoice.balance == ''?0:invoice.balance) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row mb-4" v-if="form_mode == 'invoice'">
                            <div class="col-12">
                                <strong>Είδος Παραστατικού: </strong><span>{{(typeof invoiceTypes[form.invoice_type_id] != 'undefined')?invoiceTypes[form.invoice_type_id]:''}}</span><br>
                                <strong>Πελάτης: </strong><span>{{(typeof clientsWithKeys[form.client_id] != 'undefined')?clientsWithKeys[form.client_id]:''}}</span><br>
                                <strong>Υπ. Παραστατικού: </strong><span>{{form.balance}}</span>
                            </div>
                        </div>


                        <div class="row">
                            <div class="form-group col-12">
                                <div class="v-input v-input--is-readonly theme--light v-input--dense v-text-field v-text-field--is-booted">
                                    <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot">
                                            <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. Πληρωμής</label>
                                            <datepicker :format="customDateFormatter" v-model="form.payment_date"></datepicker>
                                        </div>
                                    </div>
                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <has-error :form="form" field="payment_date"></has-error>
                            </div>
                        </div>
                        <div class="row" v-if="!editmode">
                            <div class="form-group col-md-12">
                                <v-text-field
                                    label="Σειρά"
                                    v-model="form.series"
                                    :readonly="true"
                                    dense
                                ></v-text-field>
                                <has-error :form="form" field="series"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <v-text-field
                                    label="Περιγραφή"
                                    v-model="form.description"
                                    dense
                                ></v-text-field>
                                <has-error :form="form" field="description"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <v-text-field
                                    label="Ποσό"
                                    v-model="amount"
                                    dense
                                ></v-text-field>
                                <has-error :form="form" field="amount"></has-error>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Κλείσιμο</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Προσθήκη</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';


    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                editmode: false,
                form_opened: false,
                form_mode: '',
                payments: {},
                invoiceTypes: [],
                clients: [],
                clientsWithKeys: [],
                invoices: [],
                form: new Form({
                    id : '',
                    type_id: 1,
                    client_id: '',
                    series: this.$gate.user.income_series,
                    invoice_id: '',
                    invoice_type_id: '',
                    description: '',
                    payment_date: new Date().toJSON().slice(0,10),
                    amount: '',
                    unpaidInvoicesSelected: [],
                    invoice_full_number: '',
                    balance: '',
                }),
                clientsFound: [],
                searchClient: '',
                searchClientEdit: '',
                loadingClients: false,
                amount: '',

                search_client_id: '',
                search_invoice_full_number: '',
                search_payment_date: '',
            }
        },
        watch: {
            amount(val) {
                this.amount = val.replaceAll(',', '.').replaceAll('..', '.');
                this.form.amount = this.amount;
            },
        },
        methods: {

          loadClients(filter = ''){
            axios.get("/api/clients/getList" + filter).then(({ data }) => (this.parseClients(data.data)));
          },
          parseClients(data){
            let clients = {};
            for (const i in data)
              clients[data[i]['id']] = data[i]['company_name'];
            this.clients = data;
            this.clientsWithKeys = clients;
          },
          searchClients(form){
            let searchData = '';
            if(form == 'editForm')
              searchData = this.searchClientEdit;
            else
              searchData = this.searchClient

            if(searchData.length == 4)
            {
              this.loadingClients = true;
              axios.get("/api/clients/getList?search="+searchData).then(({ data }) => (this.clientsFound = data.data));
              this.loadingClients = false;
            }
          },
          loadSearchedPayments(date = ''){

              if(date != '')
                this.search_payment_date = date;

              //search all fields
              let searchFields = ['search_client_id', 'search_invoice_full_number', 'search_payment_date'];
              let filter = [];
              let data = '';
              for (let i = 0; i < searchFields.length; i++)
              {
                if(this[searchFields[i]] != null && this[searchFields[i]] != '')
                {
                  if(searchFields[i] == 'search_payment_date')
                    data =  this.$moment(this[searchFields[i]]).format('yyyy-MM-DD');
                  else
                    data =  this[searchFields[i]];

                  filter.push(searchFields[i] + '=' + data);
                }
              }

              let filterText = '';
              if(filter.length > 0)
                filterText = '&' + filter.join('&');
                
              this.loadPayments(1, filterText);
          },



          loadPayments(page = 1, filter='') {
              this.$Progress.start();
              axios.get('/api/payments?page=' + page + filter).then(({ data }) => {
                  this.payments = data.data;

                  data = data.data.data;
                  let client_ids = [];
                  for (let i = 0; i < data.length; i++)
                      client_ids.push(data[i].client_id);

                  this.loadClients('?in=' + client_ids.join(','));
              });
              this.$Progress.finish();
          },
          loadInvoiceTypes(){
            axios.get("/api/invoiceTypes/getListKeyValue").then(({ data }) => (this.invoiceTypes = data.data));
          },
          loadUnpaidInvoices(){
              if(this.form.client_id == 0)
                return;
              axios.get('/api/clients/getUnpaidInvoices/' + this.form.client_id).then(({ data }) => (this.invoices = data.data));
          },
          getInvoiceData(){
              axios.get('/api/invoices/getInvoiceByNumber/' + this.form.invoice_full_number).then(({ data }) => (this.parseInvoiceData(data.data)));
          },
          parseInvoiceData(data){

              //έλεγχος αν βρέθηκαν παραστατικά
              if(data.length == 0)
              {
                  alert('Το παραστατικό με τον αριθμό αυτό δεν βρέθηκε.');
                  this.form.invoice_id = '';
                  this.form.client_id = '';
                  this.form.balance = '';
                  this.form.invoice_type_id = '';
                  this.invoices = [];
                  return;
              }

              //έλεγχος αν αυτά τα παραστατικά είναι πληρωμένα
              let d = [];
              for (let i = 0; i < data.length; i++)
              {
                if(data[i].balance == 0)
                {
                    if(data.length == 1)
                    {
                        alert('Το παραστατικό αυτό είναι πληρωμένο.');
                        this.form.invoice_id = '';
                        this.form.client_id = '';
                        this.form.balance = '';
                        this.form.invoice_type_id = '';
                        this.invoices = [];
                        return;
                    }
                    else
                    {
                        continue;
                    }

                }
                d.push(data[i]);
                  
              }

              // μήνυμα αν όλα τα παραστατικά είναι πληρωμένα
              if(d.length == 0)
              {
                  alert('Όλα τα παραστατικά με τον αριθμό αυτό είναι πληρωμένα.');
                  return;
              }

              //αν βρεθεί ένα παραστατικό το επιλέγουμε απευθείας
              if(d.length == 1)
              {
                this.form.invoice_id = d[0].id;
                this.form.client_id = d[0].client_charged_id;
                this.form.balance = d[0].balance;
                this.form.invoice_type_id = d[0].invoice_type_id;

                this.clientsWithKeys[d[0].client_charged_id] = d[0].client;
                this.invoices = [];
                return;
              }


              //set invoices table
              this.invoices = d;
              this.form.invoice_id = '';
              this.form.client_id = '';
              this.form.balance = '';
              this.form.invoice_type_id = '';
          },
          selectInvoice(invoice_id){
              for (let i = 0; i < this.invoices.length; i++)
              {
                  if(this.invoices[i].id == invoice_id)
                  {
                    this.form.invoice_id = this.invoices[i].id;
                    this.form.client_id = this.invoices[i].client_charged_id;
                    this.form.balance = this.invoices[i].balance;
                    this.form.invoice_type_id = this.invoices[i].invoice_type_id;
                    this.clientsWithKeys[this.invoices[i].client_charged_id] = this.invoices[i].client;
                  }
              }
              this.invoices = [];
          },


          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },



          editModal(payment){
              this.invoices = '';
              this.form_mode = '';
              this.editmode = true;
              this.amount = '';
              this.form.reset();
              this.amount = payment.amount;
              $('#addNew').modal('show');
              this.form.fill(payment);
          },
          newModal(mode){
              this.invoices = '';
              this.form_mode = mode;
              this.editmode = false;
              this.amount = '';
              this.form.reset();
              $('#addNew').modal('show');
          },
          createPayment(){
              this.$Progress.start();

              //send combobox value if no doy is selected
              if(this.form.company_doy_id == "" && this.searchDoy.length > 0)
                  this.form.company_doy_id = this.searchDoy;

              this.form.post('api/payments')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadPayments();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updatePayment(){
              this.$Progress.start();

              //send combobox value if no doy is selected
              if(this.form.company_doy_id == "" && this.searchDoy.length > 0)
                  this.form.company_doy_id = this.searchDoy;

              this.form.put('api/payments/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadPayments();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadInvoiceTypes();
            this.loadPayments();
            // this.loadClients();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
